import React from 'react';
const RCA = (props) => {
    const {
        fillColor = "#2F67F8",
        ...rest                
      } = props;
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 22.3496V19.9052L2.94444 17.4607V22.3496H0.5ZM5.38889 22.3496V15.0163L7.83333 12.5718V22.3496H5.38889ZM10.2778 22.3496V12.5718L12.7222 15.0468V22.3496H10.2778ZM15.1667 22.3496V15.0468L17.6111 12.6024V22.3496H15.1667ZM20.0556 22.3496V10.1274L22.5 7.68294V22.3496H20.0556ZM0.5 16.0246V12.5718L9.05556 4.01628L13.9444 8.90516L22.5 0.349609V3.80239L13.9444 12.3579L9.05556 7.46905L0.5 16.0246Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default RCA;