import React from 'react';
const Recommendation = (props) => {
    const {
        fillColor = "#2F67F8",
        ...rest                
      } = props;
  return (
    <svg
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55332 13.1855H16.2533V4.08555H4.55332V13.1855ZM3.25332 20.9855C2.53832 20.9855 1.92624 20.731 1.41707 20.2218C0.907904 19.7126 0.65332 19.1005 0.65332 18.3855V2.78555C0.65332 2.07055 0.907904 1.45846 1.41707 0.949297C1.92624 0.44013 2.53832 0.185547 3.25332 0.185547H24.0533C24.7683 0.185547 25.3804 0.44013 25.8896 0.949297C26.3987 1.45846 26.6533 2.07055 26.6533 2.78555V18.3855C26.6533 19.1005 26.3987 19.7126 25.8896 20.2218C25.3804 20.731 24.7683 20.9855 24.0533 20.9855H3.25332ZM3.25332 18.3855H24.0533V2.78555H3.25332V18.3855Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default Recommendation;