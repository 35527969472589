import React from 'react';

function FileIcon({ width = 19, height = 20, strokeColor  }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.46008 15.0425H11.729V12.9657H4.46008V15.0425ZM4.46008 10.8889H14.8443V8.81201H4.46008V10.8889ZM4.46008 6.73517H14.8443V4.65833H4.46008V6.73517ZM2.38324 19.1962C1.81211 19.1962 1.32318 18.9929 0.916469 18.5862C0.509754 18.1794 0.306396 17.6905 0.306396 17.1194V2.58148C0.306396 2.01035 0.509754 1.52143 0.916469 1.11471C1.32318 0.707996 1.81211 0.504639 2.38324 0.504639H16.9211C17.4923 0.504639 17.9812 0.707996 18.3879 1.11471C18.7946 1.52143 18.998 2.01035 18.998 2.58148V17.1194C18.998 17.6905 18.7946 18.1794 18.3879 18.5862C17.9812 18.9929 17.4923 19.1962 16.9211 19.1962H2.38324ZM2.38324 17.1194H16.9211V2.58148H2.38324V17.1194Z"
        fill={strokeColor}
      />
    </svg>
  );
}

export default FileIcon;
