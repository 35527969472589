import React, { useRef, useEffect, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useAudioContext } from './AudioContext';

const WaveFormOne = ({ audioUrl }) => {
    const waveformRef = useRef(null);
    const waveSurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [localAudioUrl, setLocalAudioUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentlyPlayingWaveSurfer, setCurrentlyPlaying } = useAudioContext();
    const abortController = useRef(null);

    useEffect(() => {
        const fetchAudio = async () => {
            abortController.current = new AbortController();
            try {
                const response = await fetch(audioUrl, { signal: abortController.current.signal });
                if (!response.ok) throw new Error('Failed to fetch audio');
                const blob = await response.blob();
                const localUrl = URL.createObjectURL(blob);
                setLocalAudioUrl(localUrl);
                setLoading(false);
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error fetching audio:', error);
                    setLoading(false); // Allow the component to render without errors

                }
            }
        };

        fetchAudio();

        return () => {
            if (abortController.current) {
                abortController.current.abort();
            }
        };
    }, [audioUrl]);

    useEffect(() => {
        if (!localAudioUrl || !waveformRef.current) return;

        try {
            waveSurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#ddd',
                progressColor: '#2C1E8D',
                cursorColor: '#2C1E8D',
                barWidth: 3,
                barRadius: 3,
                cursorWidth: 1,
                height: 50,
                normalize: true,
                responsive: true,
            });

            const onPlay = () => setIsPlaying(true);
            const onPause = () => setIsPlaying(false);
            const onFinish = () => setIsPlaying(false);

            waveSurfer.current.on('play', onPlay);
            waveSurfer.current.on('pause', onPause);
            waveSurfer.current.on('finish', onFinish);
            waveSurfer.current.on('ready', () => setLoading(false)); 
            waveSurfer.current.on('error', (err) => {
                console.error('WaveSurfer error:', err);
                setLoading(false); // Handle error gracefully
            });

            waveSurfer.current.load(localAudioUrl);

            return () => {
                if (waveSurfer.current) {
                    waveSurfer.current.un('play', onPlay);
                    waveSurfer.current.un('pause', onPause);
                    waveSurfer.current.un('finish', onFinish);
                    waveSurfer.current.destroy();
                }
            };
        } catch (error) {
            console.error('Error initializing WaveSurfer:', error);
            setLoading(false); // Handle error gracefully
        }
    }, [localAudioUrl]);

    const handlePlayPause = useCallback(() => {
        if (currentlyPlayingWaveSurfer && currentlyPlayingWaveSurfer !== waveSurfer.current) {
            currentlyPlayingWaveSurfer.pause();
        }

        if (isPlaying) {
            waveSurfer.current.pause();
        } else {
            waveSurfer.current.play();
            setCurrentlyPlaying(waveSurfer.current);
        }
    }, [isPlaying, currentlyPlayingWaveSurfer, setCurrentlyPlaying]);

    if (loading) {
        return <div >Loading...</div>; // Show loading while fetching audio
    }
    return (
        <div className="space-btn-center" style={{ gap: "0.625rem" }}>
           
             
                    <div ref={waveformRef} style={{ flex: 1, height: '50px' }}></div>
                    <div>
                <button onClick={handlePlayPause} className="audioNew center_div_nogap">
                {isPlaying ? <PauseIcon fontSize="medium" style={{color:"#2C1E8D"}} /> : <PlayArrowIcon fontSize="medium"  style={{color:"#2C1E8D"}} />}

                </button>
            </div>
        </div>
    );
};

export default WaveFormOne;
