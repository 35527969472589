import React from 'react';

const AbtIcon = (props) => {
  const {
    fillColor = '#111111', 
    strokeWidth = 1.5,     
    width = "24",
    height = "24",
    ...rest                
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest} 
    >
      <path
        d="M15.2854 10.8703L13.0588 14.4094C12.9677 14.5356 12.8569 14.6464 12.7307 14.7375C12.4259 14.9501 12.0496 15.034 11.6834 14.9708C11.3172 14.9077 10.9906 14.7027 10.7746 14.4003C10.5586 14.0979 10.4706 13.7225 10.5297 13.3557C10.5887 12.9888 10.7901 12.66 11.09 12.4406L14.6291 10.2141C14.7096 10.158 14.8053 10.1279 14.9033 10.1279C15.0014 10.1279 15.0971 10.158 15.1775 10.2141C15.2786 10.287 15.3466 10.397 15.3668 10.52C15.387 10.6429 15.3577 10.7689 15.2854 10.8703Z"
        stroke={fillColor}
        stroke-width={strokeWidth}
      />
      <path
        d="M12 3C6.20157 3 1.50001 7.69687 1.50001 13.4953C1.49645 16.0607 2.43565 18.538 4.13907 20.4562C4.19063 20.5125 4.23751 20.5687 4.28907 20.6203C4.4 20.7404 4.53465 20.8361 4.68451 20.9015C4.83436 20.9668 4.99615 21.0003 5.15963 20.9999C5.32311 20.9995 5.48472 20.9651 5.63422 20.8989C5.78372 20.8328 5.91786 20.7363 6.02813 20.6156C6.78822 19.7896 7.71139 19.1303 8.7393 18.6793C9.76721 18.2282 10.8775 17.9954 12 17.9954C13.1225 17.9954 14.2328 18.2282 15.2607 18.6793C16.2886 19.1303 17.2118 19.7896 17.9719 20.6156C18.0822 20.7363 18.2163 20.8328 18.3658 20.8989C18.5153 20.9651 18.6769 20.9995 18.8404 20.9999C19.0039 21.0003 19.1657 20.9668 19.3155 20.9015C19.4654 20.8361 19.6 20.7404 19.7109 20.6203L19.8609 20.4562C21.5644 18.538 22.5036 16.0607 22.5 13.4953C22.5 7.69687 17.7984 3 12 3Z"
        stroke={fillColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 6V7.5M19.5 13.5H18M6 13.5H4.5M7.75734 9.25734L6.69656 8.19656M16.2427 9.25734L17.3034 8.19656"
        stroke={fillColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AbtIcon;
