import React, { useEffect, useRef, useState } from "react";
import "./Chatbot.scss";
import TextField from "@mui/material/TextField";
import MicRecorder from "mic-recorder-to-mp3";
import "./FontSizes.scss";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { CosmicInverterService } from "../Services/CosmicInverterService";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import Waveform from "./Waveform";
import WaveFormOne from "./WaveFormOne";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { AudioContext } from "./AudioContext";

const invService = new CosmicInverterService();
const styles = {
  chatWindow: {
    display: "flex",
    height: "56vh",
    width: "95%",
    flexDirection: "column",
    overflowY: "auto",
    border: "0.0625rem solid #F4F4F4",
    padding: "1rem 0.5rem",
    borderRadius: "0.3125rem",
    position: "relative",
  },
  profileImage: {
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "50%",
  },
  userMessage: {
    alignSelf: "flex-end",
    textAlign: "right",
    width: "100%",
    marginRight: "0.625rem",
  },
  messageDiv: {
    backgroundColor: "#ECF5FF",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: "20%",
    marginBottom: "0.625rem",
  },
  userDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "20%",
    marginBottom: "0.625rem",
    backgroundColor: "#ECF5FF",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    flexDirection: "column",
  },
  aiMessage: {
    alignSelf: "flex-start",
    width: "100%",
    textAlign: "left",
  },
};
function VoiceChat(props) {
  const { handleClose } = props;

  const [activeIndex, setActiveIndex] = useState(-1);
  const [isPreTemplate, setIsPreTemplate] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));
  const [currentlyPlayingWaveSurfer, setCurrentlyPlaying] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [userMessages, setUserMessages] = useState([
    { role: "ai", content: "" },
  ]);

  const [loadingState, setLoadingState] = useState({
    chatData: false,
    chatHistory: false,
    singleChat: false,
  });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const chatWindowRef = useRef(null);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [userMessages, loadingState.chatData]);

  const handleCloseBox = () => {
    if (isFullScreen) {
      setIsFullScreen(false);
    }
    props.handleClose(false);
  };

  const sendChatMessage = async (file) => {
    setLoadingState((prevState) => ({ ...prevState, chatData: true }));
    setIsPreTemplate(true);

    const audioUrl = URL.createObjectURL(file);

    setUserMessages((prevUserMessages) => [
      ...prevUserMessages,
      { role: "user", content: audioUrl },
    ]);

    const formData = new FormData();
    formData.append("audio", file);

    if (activeIndex !== -1) {
      formData.append("conversation_id", activeIndex);
    }

    try {
      const res = await invService.postVoiceChatbot(formData);

      if (res.status === 200 && res.data) {
        const newId = res.data.id;
        if (newId) {
          setActiveIndex(newId);
        }

        setUserMessages((prevUserMessages) => [
          ...prevUserMessages,
          {
            role: "ai",
            content: {
              audioContent: res.data.audio_url,
              imgContent: res.data.image_url,
            },
          },
        ]);
      } else if (res?.data?.length <= 0) {
        enqueueSnackbar("No data available,", {
          variant: "warning",
        });
      }
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));
      console.log(err);
    }
  };

  const startRecording = () => {
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => console.error(e));
  };

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const audioUrl = URL.createObjectURL(blob);
        setIsRecording(false);

        const file = new File([blob], "recording.mp3", { type: "audio/mpeg" });

        sendChatMessage(file);
      })
      .catch((e) => {
        console.error("Error while stopping the recording:", e);
        setIsRecording(false);
      });
  };

  const handleVoiceNote = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };
  return (
    <div className="home-container-chatbot">
      <div className="handleclose-div" id="div_data">
        {isFullScreen ? (
          <Tooltip title="ExitFullScreen" arrow>
            <button
              onClick={handleFullScreenToggle}
              className="full_btn expert_div"
            >
              <FullscreenExitIcon
                sx={{
                  width: "1.6vw !important",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#00529A",
                }}
              />
            </button>
          </Tooltip>
        ) : (
          <div onClick={handleCloseBox} className="handleclose-subdiv">
            <p className="Poppins_15px_black" style={{ color: "#2F67F8" }}>
              Close
            </p>
          </div>
        )}

        {!isPreTemplate ? (
          <>
            <div className="chat-middle-div">
              <div className="chat-gif-div">
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/videos/chatbot_GIF.gif"
                  alt="chat-gif"
                  style={{ width: "12rem" }}
                />
                <h5 className="Poppins_24px_black" id="welcome_text">
                  Welcome to VoiceOverGPT
                </h5>
                <p className="Poppins_16px_400">
                  Ask me anything about your data like status, past
                </p>
                <p className="Poppins_16px_400">
                  insights, even future predictions also.
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <AudioContext.Provider
              value={{ currentlyPlayingWaveSurfer, setCurrentlyPlaying }}
            >
              <div
                style={styles.chatWindow}
                className="msgview"
                ref={chatWindowRef}
              >
                {userMessages
                  .filter((message) => message.content !== "")
                  .map((message, index) => (
                    <div key={index}>
                      {message.role === "user" ? (
                        <div style={styles.messageDiv}>
                          <div style={styles.userMessage}>
                            <p className="chatText" id="usermsg">
                              <Waveform
                                audioUrl={message.content}
                                remoteurl={false}
                              />
                            </p>
                          </div>
                          <img
                            src="https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg"
                            alt="User Profile"
                            style={styles.profileImage}
                          />
                        </div>
                      ) : (
                        <div style={styles.userDiv} className="gap_1vw">
                          {message.content.imgContent && (
                            <>
                              {!isFullScreen && (
                                <Tooltip title="FullScreen" arrow>
                                  <button
                                    onClick={handleFullScreenToggle}
                                    className="full_btn expert_div"
                                  >
                                    <FullscreenIcon
                                      sx={{
                                        width: "1.6vw !important",
                                        height: "fit-content",
                                        cursor: "pointer",
                                        color: "#00529A",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              )}
                              <div
                                className="expert_div"
                                style={{
                                  position: isFullScreen ? "fixed" : "relative",
                                  top: isFullScreen ? "10%" : "unset",
                                  left: isFullScreen ? 0 : "unset",
                                  width: isFullScreen ? "100%" : "auto",
                                  height: isFullScreen ? "90%" : "auto",
                                  background: isFullScreen
                                    ? "rgba(0, 0, 0, 0.8)"
                                    : "unset",
                                  zIndex: isFullScreen ? 999 : "unset",
                                }}
                              >
                                <img
                                  src={message.content.imgContent}
                                  className="height_w"
                                  alt={`${index} graph `}
                                />
                              </div>
                            </>
                          )}
                          <div
                            style={styles.aiMessage}
                            className="foo_one gap_1vw"
                          >
                            <img
                              src="https://s3.amazonaws.com/smarttrak.co/v2Images/botAudio.svg"
                              alt="User Profile"
                              style={styles.profileImage}
                            />

                            <p className="chatText div_width" id="usermsg">
                              <WaveFormOne
                                audioUrl={message.content.audioContent}
                              />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                {loadingState.chatData && (
                  <div style={styles.aiMessage} className="loaderdiv">
                    <p className="loader"></p>
                  </div>
                )}
              </div>
            </AudioContext.Provider>
            {loadingState.singleChat && (
              <SimpleBackdrop open={loadingState.singleChat} />
            )}
          </>
        )}

        <div className="main-message-box">
          <div style={{ width: "100%", display: "flex" }}>
            <TextField
              id="text-chat "
              value={""}
              className={
                isRecording ? "recording-animation message-box" : "message-box"
              }
              autoComplete="off"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={`send-arrow arrow-bg mic-circle ${
                        isRecording ? "active" : ""
                      }`}
                      style={{
                        backgroundColor: isRecording ? "#4CAF50" : "#E6F1ED", // Green when recording

                        marginRight: "8px",
                      }}
                      onClick={handleVoiceNote}
                    >
                      <Tooltip
                        title={
                          isRecording ? "Stop Recording" : "Start Recording"
                        }
                      >
                        <KeyboardVoiceIcon />
                      </Tooltip>
                    </div>
                  </div>
                ),
                classes: { input: "Poppins_16px_400" },
              }}
              placeholder="Ask by voice........"
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoiceChat;
