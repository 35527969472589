import React from "react";
import Navbar from "../MainLandingPage/Navbar";
import Footer from "../MainLandingPage/Footer";
import "./TermsConditions.scss";

function TermsConditions() {
    return (
        <div className="landing-container">
            <Navbar />
            <div className="slide">
                <img
                    style={{
                        width: "100%",
                        background:
                            "linear-gradient(357.77deg, rgba(253, 167, 55, 0.6) 3.34%, rgba(0, 82, 154, 0.6) 98.44%)",
                    }}
                    src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/Banner.png"
                    alt="privacypolicys"
                />
                <div className="products_overlay" id="privacybanner">
                    <p className="raleway_64px_rem">Terms & Conditions</p>
                </div>
            </div>
            <div className="privacydata">
                <div className="privacy_text">
                    <p className="raleway_20px_reg">
                        This section represents the Terms and Conditions (“Terms”) governing the use of the Service provided by Smarttrak AI, including any associated sub-domains, web-based, and mobile applications (collectively, the “Service”). These Terms, along with our Privacy Policy and any additional posted guidelines, constitute the entire agreement between you and Smarttrak AI regarding your use of the Service.
                        <br /><br />
                        Capitalized terms, unless otherwise defined, have the meanings assigned to them in the Definitions section below. By accessing or using the Service, you agree to comply with and be bound by these Terms. Please review them carefully. If you do not accept these Terms, you must immediately cease using the Service.
                        <br /><br />
                        Smarttrak AI reserves the right to amend these Terms at any time without prior notice. The most current version will always be posted on our website. It is your responsibility to review the Terms periodically. Continued use of the Service after updates constitutes your acceptance of the revised Terms.
                        <br /><br />
                        If you have questions about these Terms or their applicability, please contact us at  <a href="mailto:info@smarttrak.co" className="mailinfo">
                            {" "}
                            info@smarttrak.co.
                        </a>{" "}

                    </p>
                </div>

                <div className="privacy_text">
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            1. Definitions
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">
                            <li><span style={{ fontWeight: "700" }}>"Smarttrak AI," "we," "our," or "us" </span>refers to Smarttrak AI, the provider of the Service.
                            </li>
                            <li><span style={{ fontWeight: "700" }}>"Service"</span> refers to the Real-time 3D Digital Twin solution and associated features provided by Smarttrak AI.
                            </li>
                            <li><span style={{ fontWeight: "700" }}>"User" or "you"</span> refers to individuals or entities accessing the Service.
                            </li>
                            <li><span style={{ fontWeight: "700" }}>"Content"</span> refers to text, data, graphics, and any other material available through the Service.
                            </li>
                        </p>{" "}
                    </div>

                    <div>
                        <p className="raleway_20px_reg pri_para blue_text">
                            2. Eligibility
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">
                            By accessing or using our Service, you confirm that you meet the eligibility requirements outlined in these Terms. You represent and warrant that you have the legal capacity and authority to enter into these Terms, and that your use of the Service complies with all applicable laws and regulations. Additionally, you agree that any information you provide to Smarttrak AI during registration or use of the Service is accurate, current, and complete, and that you will promptly update such information as necessary.
                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            3. Access and Authentication
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">
                            <li>
                                Access to the Service is restricted to registered users who have been issued credentials by Smarttrak AI. Only users authorized under a valid agreement with Smarttrak AI, such as organizational representatives or employees, are permitted to register and access the Service. Unauthorized use of the Service by individuals or entities without proper credentials is strictly prohibited.
                            </li>
                            <li>
                                As a registered user, you are solely responsible for maintaining the confidentiality and security of your login credentials, including your username and password. You must not share your credentials with any third party or permit others to access the Service using your credentials. Any activity conducted using your credentials will be considered as authorized by you.
                            </li>
                            <li>
                                If you suspect that your login credentials have been compromised or accessed without your authorization, you must notify Smarttrak AI immediately at  <a href="mailto:info@smarttrak.co" className="mailinfo">
                                    {" "}
                                    info@smarttrak.co.
                                </a>{" "}. You are responsible for any unauthorized access or actions taken on your account prior to notifying Smarttrak AI of the security breach.
                            </li>
                            <li>
                                Smarttrak AI reserves the right to monitor access to the Service and may suspend or revoke your credentials if we believe there has been a violation of these Terms, unauthorized access, or misuse of the Service.
                            </li>
                            <li>
                                By accessing the Service, you agree to use only the credentials assigned to you and to log out after each session to prevent unauthorized access. Smarttrak AI is not liable for any loss or damage resulting from your failure to comply with this section.

                            </li>
                        </p>
                    </div>

                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            4. User Obligations
                        </p>
                        <br />
                        <p className="raleway_20px_reg pri_para">
                            By using the Service, you agree to:
                        </p>
                        <p className="raleway_20px_reg ">
                            <li>
                                Provide accurate and current information when registering for the Service.
                            </li>
                            <li>
                                Comply with all applicable local, state, national, and international laws when using the Service. This includes data privacy laws, export control laws, and any regulations specific to your industry (e.g., solar energy regulations).
                            </li>
                            <li>
                                Respect the rights of other users and not engage in disruptive, abusive, or illegal behavior while using the Service.
                            </li>
                            <li>
                                Maintain the confidentiality of your account information and secure your login credentials to prevent unauthorized access to the Service.
                            </li>
                            <li>
                                Not use the Service for any unlawful purposes or to transmit, distribute, or store material that violates the rights of others or is prohibited by these Terms.
                            </li>
                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            5. License to Use the Service
                        </p>
                        <p className="raleway_20px_reg ">
                            <li>
                                We grant you a non-exclusive, revocable, non-transferable, and limited license to use the Service in accordance with these Terms.
                            </li>
                        </p>
                        <p className="raleway_20px_reg pri_para">
                            You may not
                        </p>
                        <p className="raleway_20px_reg ">
                            <li>
                                Copy, modify, or distribute the Service.
                            </li>
                            <li>
                                Reverse engineer, decompile, or attempt to extract the source code of any part of the Service.
                            </li>
                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            6. Data Collection and Privacy
                        </p>
                        <br />
                        <p className="raleway_20px_reg pri_para">
                            By using the Service, you consent to the collection and processing of your data as outlined in our  <span style={{ fontWeight: "700", cursor: "pointer" }}><a className="mailinfo" href="/privacypolicy">Privacy Policy</a></span>. We collect the following types of data:
                        </p>
                        <p className="raleway_20px_reg ">
                            <li>
                                <span style={{ fontWeight: "700" }}>Personal Information:</span> Name, email, phone number, and other details you provide.

                            </li>
                            <li>
                                <span style={{ fontWeight: "700" }}>Usage Data:</span> Interaction data, device information, and browsing details.

                            </li>
                            <li>
                                <span style={{ fontWeight: "700" }}>Operational Data:</span> Performance metrics and system-generated data.

                            </li>
                        </p>
                        <p className="raleway_20px_reg pri_para">
                            You have the right to access, correct, or request the deletion of your data as per applicable laws. For more details, please refer to our <span style={{ fontWeight: "700", cursor: "pointer" }}><a className="mailinfo" href="/privacypolicy">Privacy Policy</a></span>.

                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            7. Intellectual Property
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">
                            <li>
                                The Service and all associated materials, including but not limited to text, graphics, logos, images, software, and underlying technology, are owned by Smarttrak AI or its licensors and are protected by copyright, trademark, and other applicable intellectual property laws.
                            </li>
                            <li>
                                You are granted a limited, non-exclusive license to use the Service solely for its intended purposes. This license does not transfer any ownership rights to you. You may not modify, copy, distribute, reproduce, or create derivative works from any part of the Service without prior written consent from Smarttrak AI.
                            </li>
                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            8. Prohibited Activities
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">
                            <li>
                                The Service and all associated materials, including but not limited to text, graphics, logos, images, software, and underlying technology, are owned by Smarttrak AI or its licensors and are protected by copyright, trademark, and other applicable intellectual property laws.
                            </li>
                            <li>
                                You are granted a limited, non-exclusive license to use the Service solely for its intended purposes, as outlined in these Terms. This license does not transfer any ownership rights to you. You may not modify, copy, distribute, reproduce, create derivative works from, or exploit any part of the Service without prior written consent from Smarttrak AI.
                            </li>
                            <li>
                                Unauthorized use of Smarttrak AI’s intellectual property is prohibited and may result in suspension or termination of access to the Service. Smarttrak AI reserves the right to take legal action to enforce its rights.
                            </li>
                            <li>
                                Any feedback or suggestions you provide to Smarttrak AI about the Service will become its exclusive property, and you waive any claim to ownership or compensation for such submissions.
                            </li>
                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            9. Third-Party Links
                        </p>
                        <br />
                        <p className="raleway_20px_reg pri_para">
                            By clicking on third-party links in our Service, you acknowledge that:
                        </p>
                        <p className="raleway_20px_reg ">

                            <li>
                                Smarttrak AI is not responsible for the content, functionality, or practices of these websites.
                            </li>
                            <li>
                                You access such links at your own risk.

                            </li>

                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            10. Service Availability
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">

                            <li>
                                Smarttrak AI strives to ensure the Service is available at all times. However, we do not guarantee uninterrupted access and are not liable for any downtime.
                            </li>
                        </p>
                    </div>
                    <div className="list_italic">
                        <p className="raleway_20px_reg pri_para blue_text">
                            11. Liability and Disclaimer
                        </p>
                        <br />
                        <p className="raleway_20px_reg ">
                            <li>
                                The Service is provided "as is" without warranties of any kind, express or implied.
                            </li>
                            <li>
                                To the fullest extent permitted by law, Smarttrak AI is not liable for:
                            </li>
                        </p>
                        <p className="raleway_20px_reg" style={{ marginLeft: "2rem" }}>
                            <li>
                                Any indirect, incidental, or consequential damages arising from your use of the Service.
                            </li>
                            <li>
                                Loss of data or interruption of business due to Service downtime.
                            </li>
                        </p>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    );
}

export default TermsConditions;