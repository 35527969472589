import React from 'react';
const Notes = (props) => {
    const {
        fillColor = "#2F67F8",
        ...rest                
      } = props;
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.94444 22.5C2.27222 22.5 1.69676 22.2606 1.21806 21.7819C0.739352 21.3032 0.5 20.7278 0.5 20.0556V2.94444C0.5 2.27222 0.739352 1.69676 1.21806 1.21806C1.69676 0.739352 2.27222 0.5 2.94444 0.5H16.3889L22.5 6.61111V20.0556C22.5 20.7278 22.2606 21.3032 21.7819 21.7819C21.3032 22.2606 20.7278 22.5 20.0556 22.5H2.94444ZM2.94444 20.0556H20.0556V7.83333H15.1667V2.94444H2.94444V20.0556ZM5.38889 17.6111H17.6111V15.1667H5.38889V17.6111ZM5.38889 7.83333H11.5V5.38889H5.38889V7.83333ZM5.38889 12.7222H17.6111V10.2778H5.38889V12.7222Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default Notes;