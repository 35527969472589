import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Paper, Grid, Box } from "@mui/material";
import "./ReportFailure.scss";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import useErrorHandler from "../../../utils/errorHandler";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { useMobile } from "../../Context/MobileContext/MobileContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import ToggleOff from "../../SVG/ToggleOff";
import ToggleOn from "../../SVG/ToggleOn";
import Notes from "../../SVG/Notes";
import Recommendation from "../../SVG/Recommendation";
import RCA from "../../SVG/RCA";
import { useHistory } from "react-router-dom";

const invService = new CosmicInverterService();

const reportsData = [
  {
      "id": "201746565",
      "raisedBy": {
          "name": "Admin",
          "email": "demo_us@smarttrak.co",
          "avatar": "https://s3.amazonaws.com/smarttrak.co/v2Images/userProfile.svg"
      },
      "resolvedBy": "--",
      "reportDate": "18-09-2024",
      "faultType": "Electrical Overload",
     "notes":{
         "device_name":"Inverter 1",
         "icon_url":"https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/Button.svg",
         "failure_description":"Electrical overload detected",
         "device_id":7
      },
      "cause":{
        "primary_cause":"Despite advanced protections, electrical overload can occur due to sudden surges in power from the solar array array potentially caused by faults in individual panels or MPPT inefficiencies"
      },
      "insight": [
        {
          title: "KPI",
          p1: "AC Power Efficiency",
          p2: "Performance Ratio",
          p3: "DC Voltage",
          p4: "Ambient Temperature",
          iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight1.svg"
        },
        {
          title: "Relevance",
          p1: "Critical",
          p2: "High",
          p3: "High",
          p4: "Moderate",
          iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight2.svg"
        },
        {
          title: "Reason",
          p1: "Below expected threshold",
          p2: "Low efficiency",
          p3: "0 kW generated",
          p4: "Frequent interruptions",
          iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight3.svg"
        },
      ],
      "recommendations":{
        "affected_system":{
          "icon_url":"	https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/power-solar.svg",
          "description":"Solar Panel Array and MPPT (Maximum Power Point Tracking)Unit"
        },
        "fault_identify":[
          {
            icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/solarpanel.svg",
            title: "Panels",
            description: "Faulty solar panels causing inconsistent or low voltage"
          },
          {
            icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/iot_device.svg",
            title: "MPPT Unit",
            description: "Inefficient tracking of power points leading to system overload"
          },
          {
            icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/cable.svg",
            title: "Wiring",
            description: " Damaged or loose connections contributing to power surges"
          },
        ],
        "action_steps":[
          {
            "step":"Disconnect the affected array and test individual panels for voltage issues",
          },
          {
            "step":"Inspect and address MPPT calibration or wiring/panel damage, replacing components as needed",
          },
          {
            "step":"Reconnect and test the system for stable, optimal performance",
          }
        ]

      }
  },
  {
      "id": "201746567",
      "raisedBy": {
          "name": "John Doe",
          "email": "john.doe@smarttrak.co",
          "avatar": "https://s3.amazonaws.com/smarttrak.co/v2Images/userProfile.svg"
      },
      "resolvedBy": "--",
      "reportDate": "24-09-2024",
      "faultType": "Temperature Sensor Failure",
     "notes":{
        "device_name":"Temperature Sensor",
        "icon_url":"https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/temp-sensor.svg",
        "failure_description":"Temperature sensor reports inconsistent or unrealistic module temperature values"
     },
     "cause":{
       "primary_cause":"Inaccurate temperature readings caused by calibration issues, faulty sensors, or external environmental effects"
     },
     "insight": [
      {
        title: "KPI",
        p1: "Energy Loss Analysis",
        p2: "Performance Ratio",
        p3: "Generated Power ",
        p4: "Sensor Status (Health Check)",
        iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight1.svg"
      },
      {
        title: "Relevance",
        p1: "Critical",
        p2: "High",
        p3: "High",
        p4: "Moderate",
        iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight2.svg"
      },
      {
        title: "Reason",
        p1: "Skewed data affects energy loss calculations",
        p2: "Irregular PR values point data inaccuracies",
        p3: "Output trends reveal impact of faulty inputs",
        p4: "Faulty sensor alerts identify the issue",
        iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight3.svg"
      },
    ],
    "recommendations":{
      "affected_system":{
        "icon_url":"https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/foggy.svg",
        "description":"Temperature Monitoring Subsystem and related modules"
      },
      "fault_identify":[
        {
          icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/sensors.svg",
          title: "Sensor",
          description: "Malfunctioning due to calibration drift or hardware defect"
        },
        {
          icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/cable.svg",
          title: "Wiring",
          description: "Potential disruptions affecting sensor signals"
        },
      ],
      "action_steps":[
        {
          "step":"Test and recalibrate temperature sensor for accuracy",
        },
        {
          "step":"Inspect and address wiring or external influences on the sensor",
        },
        {
          "step":"Replace faulty sensors and verify consistent readings",
        }
      ]

    }
  },
  {
      "id": "201746568",
      "raisedBy": {
          "name": "Jane Smith",
          "email": "jane.smith@smarttrak.co",
          "avatar": "https://s3.amazonaws.com/smarttrak.co/v2Images/userProfile.svg"
      },
      "resolvedBy": "--",
      "reportDate": "22-08-2024",
      "faultType": "Firmware Update Required",
       "notes":{
        "device_name":"Inverter 1",
        "icon_url":"https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/power.svg",
        "failure_description":"The inverter requires a firmware update to fix known bugs and improve performance"
     },
     "cause":{
       "primary_cause":"Failure to regularly update the firmware can lead to performance issues and potential inverter failures"
     },
     "insight": [
      {
        title: "KPI",
        p1: "Firmware Version Status",
        p2: "AC Power Efficiency",
        p3: "Inverter Uptime ",
        p4: "Performance Ratio",
        iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight1.svg"
      },
      {
        title: "Relevance",
        p1: "Critical",
        p2: "High",
        p3: "High",
        p4: "Moderate",
        iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight2.svg"
      },
      {
        title: "Reason",
        p1: "Outdated firmware caused the issue",
        p2: "Bugs in outdated firm reduce efficiency",
        p3: "Missing updates lead to stability issues",
        p4: "Efficiency and energy ratios are affected",
        iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight3.svg"
      },
    ],
    "recommendations":{
      "affected_system":{
        "icon_url":"	https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/foggy.svg",
        "description":"Inverter and associated firmware-dependent modules"
      },
      "fault_identify":[
        {
          icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/firmware.svg",
          title: "Firmware",
          description: "Outdated version leading to bugs and inefficiencies"
        },
        {
          icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/backup.svg",
          title: "Configuration Files",
          description: "Compatibility issues with older firmware"
        },
        
      ],
      "action_steps":[
        {
          "step":"Schedule and execute the firmware update as soon as possible",
        },
        {
          "step":"Ensure all system backups are taken before proceeding with the update",
        },
        {
          "step":"Test system performance after the update to validate improvements",
        }
      ]

    }
  }
]
const inactiveReportsData = [
  {
    "id": "201746566",
    "raisedBy": {
        "name": "Admin",
        "email": "demo_us@smarttrak.co",
        "avatar": "https://s3.amazonaws.com/smarttrak.co/v2Images/userProfile.svg"
    },
    "resolvedBy": "Operations Specialist",
    "reportDate": "01-09-2024",
    "faultType": "Electrical Overload",
     "notes":{
      "device_name":"Inverter 1",
      "icon_url":"https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/Button.svg",
      "failure_description":"Electrical overload detected"
   },
   "cause":{
     "primary_cause":"Despite advanced protections, electrical overload can occur due to sudden surges in power from the solar array array potentially caused by faults in individual panels or MPPT inefficiencies"
   },
   "insight": [
    {
      title: "KPI",
      p1: "AC Power Efficiency",
      p2: "Performance Ratio",
      p3: "DC Voltage",
      p4: "Ambient Temperature",
      iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight1.svg"
    },
    {
      title: "Relevance",
      p1: "Critical",
      p2: "High",
      p3: "High",
      p4: "Moderate",
      iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight2.svg"
    },
    {
      title: "Reason",
      p1: "Below expected threshold",
      p2: "Low efficiency",
      p3: "0 kW generated",
      p4: "Frequent interruptions",
      iconURL: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/insight3.svg"
    },
  ],
  "recommendations":{
    "affected_system":{
      "icon_url":"	https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/power-solar.svg",
      "description":"Solar Panel Array and MPPT (Maximum Power Point Tracking)Unit"
    },
    "fault_identify":[
      {
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/solarpanel.svg",
        title: "Panels",
        description: "Faulty solar panels causing inconsistent or low voltage"
      },
      {
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/iot_device.svg",
        title: "MPPT Unit",
        description: "Inefficient tracking of power points leading to system overload"
      },
      {
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/cable.svg",
        title: "Wiring",
        description: " Damaged or loose connections contributing to power surges"
      },
    ],
    "action_steps":[
      {
        "step":"Disconnect the affected array and test individual panels for voltage issues",
      },
      {
        "step":"Inspect and address MPPT calibration or wiring/panel damage, replacing components as needed",
      },
      {
        "step":"Reconnect and test the system for stable, optimal performance",
      }
    ]

  }
  },
]
const ReportRow = ({ report }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [detailsTabs, setDetailsTabs] = useState("Notes")
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth <= 600); 
  const [activeTab, setActiveTab] = useState(null); 
  const { isMobile } = useMobile();
  const history = useHistory();
  useEffect(() => {
    const handleResize = () => setIsSmallMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleDetailstabVal = (val) => {
    setDetailsTabs(val);
    if (isSmallMobile) {
      setActiveTab(val); 
    }
  };

  const handleDetailDiagnostic = (faultType) =>{
    history.push({
      pathname: "/iot-home/fault_analysis/detailed_diagnostic",
      state: {
        deviceId: 7,
        fault_type: faultType
      },
    });
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Grid
        container
        className="failure-data-Container"
        sx={{
          borderRadius: isExpanded ? "0.625rem 0.625rem 0rem 0rem" : "0.625rem",
        }}
      >
        {isMobile ?
          <div className="mobile_flex_fullwidth">
            <div className="mobile_flex_center">
              <Grid item xs={4.9} sm={3.9} md={3.9}>
                <p className="Poppins_16px_rem">Report ID</p>
                <p className="report_400">{report.id}</p>
              </Grid>
              <Grid item xs={4.9} sm={3.9} md={3.9} className="media_overflow-y">
                <p className="Poppins_16px_rem">Fault Type</p>
                <p className="report_400" style={{ color: "#FF472E" }}>
                  {report.faultType}
                </p>
              </Grid>

            </div>
            <Grid item xs={1.9} sm={2.9} md={2.9}>
              <div onClick={handleToggleExpand} className="open-close-btn">
                {isExpanded ? (
                  <>
                    <ExpandLessIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
                  </>
                ) : (
                  <>
                    <ExpandMoreIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
                  </>
                )}
              </div>
            </Grid>
          </div> :
          <><Grid item xs={0.5}>
            <img
              src={report.raisedBy.avatar}
              alt="avatar"
              className="report_img" />
          </Grid><Grid item xs={2}>
              <p className="report_400">{report.raisedBy.name}</p>
              <p className="Poppins_12px_rem" style={{ color: "#969696" }}>
                {report.raisedBy.email}
              </p>
            </Grid></>}
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400">{report.id}</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400">{report.resolvedBy}</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400">{report.reportDate}</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400" id="scrollingHistory" style={{ color: "#FF472E" }}>
            {report.faultType}
          </p>
        </Grid>
        <Grid item xs={1.5} className="mobile_hide">
          <div onClick={handleToggleExpand} className="open-close-btn">
            {isExpanded ? (
              <>
                <p className="report_400" style={{ color: "#2F67F8" }}>
                  Close Details
                </p>
                <ExpandLessIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
              </>
            ) : (
              <>
                <p className="report_400" style={{ color: "#2F67F8" }}>
                  View Details
                </p>
                <ExpandMoreIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
              </>
            )}
          </div>
        </Grid>
      </Grid>
      {isExpanded && (
        <Paper className="expanded_paper">

          {isMobile && <Grid container className="expanded_container" sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12} sm={12} md={3.9}>
              <Paper className="flex_col_paper">
                <p className="Poppins_16px_rem">Raised By</p>
                <div className="flex_div">
                  <img
                    src={report.raisedBy.avatar}
                    alt="avatar"
                    className="report_img"
                  />
                  <div>
                    <p className="report_400">{report.raisedBy.name}</p>
                    <p className="Poppins_12px_rem" style={{ color: "#969696" }}>
                      {report.raisedBy.email}
                    </p>
                  </div>
                </div>
              </Paper>

            </Grid>


            <Grid item xs={5.6} sm={5.6} md={2.8} >
              <p className="Poppins_16px_rem">Resolved By</p>
              <p className="report_400">{report.resolvedBy}</p>
            </Grid>
            <Grid item xs={5.6} sm={5.6} md={2.8} >
              <p className="Poppins_16px_rem">Report Date</p>
              <p className="report_400">{report.reportDate}</p>
            </Grid>
          </Grid>}
          <Grid container className="expanded_container" sx={{ alignItems: "stretch !important" }}>
            <Grid item lg={11.9} xs={12} sm={12} md={11.9} className="first_grid">
              <Paper className="first_paper">
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={detailsTabs}
                    onChange={(event, newValue) => handleDetailstabVal(newValue)}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: detailsTabs === "Notes" || "Root Cause Analysis" || "Recommendations" ? "#2F67F8" : "#727272", 
                      },
                    }}
                    variant="fullWidth"
                  >
                    <Tab
                      sx={{ color: detailsTabs === "Notes" ? "#2F67F8 !important" : "#727272 !important" }}
                      value="Notes"
                      className="tabTextColor"
                      label={
                        isSmallMobile ? (
                          <Tooltip
                            title="Notes"
                            open={activeTab === "Notes"}
                          >
                            <div>
                              <Notes fillColor={detailsTabs === "Notes" ? "#2F67F8" : "#727272"} />
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="green-tab">
                            <Notes fillColor={detailsTabs === "Notes" ? "#2F67F8" : "#727272"} />
                            <p
                              className="Poppins_20px_rem"
                              style={{
                                fontWeight: "400",
                                color: detailsTabs === "Notes" ? "#2F67F8" : "#727272",
                              }}
                            >
                              Notes
                            </p>
                          </div>
                        )
                      
                      }
                    />
                    <Tab
                      sx={{ color: detailsTabs === "Root Cause Analysis" ? "#2F67F8 !important" : "#727272 !important" }}
                      value="Root Cause Analysis"
                      label={
                        isSmallMobile ? (
                          <Tooltip
                            title="Root Cause Analysis"
                            open={activeTab === "Root Cause Analysis"}
                          >
                            <div>
                              <RCA fillColor={detailsTabs === "Root Cause Analysis" ? "#2F67F8" : "#727272"} />
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="green-tab">
                            <RCA fillColor={detailsTabs === "Root Cause Analysis" ? "#2F67F8" : "#727272"} />
                            <p
                              className="Poppins_20px_rem"
                              style={{
                                fontWeight: "400",
                                color: detailsTabs === "Root Cause Analysis" ? "#2F67F8" : "#727272",
                              }}
                            >
                              Root Cause Analysis
                            </p>
                          </div>
                        )
                      }
                      className="tabTextColor"
                    />
                    <Tab
                      sx={{ color: detailsTabs === "Recommendations" ? "#2F67F8 !important" : "#727272 !important" }}
                      value="Recommendations"
                      label={
                        isSmallMobile ? (
                          <Tooltip
                            title="Recommendations"
                            open={activeTab === "Recommendations"}
                          >
                            <div>
                              <Recommendation fillColor={detailsTabs === "Recommendations" ? "#2F67F8" : "#727272"} />
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="green-tab">
                            <Recommendation fillColor={detailsTabs === "Recommendations" ? "#2F67F8" : "#727272"} />
                            <p
                              className="Poppins_20px_rem"
                              style={{
                                fontWeight: "400",
                                color: detailsTabs === "Recommendations" ? "#2F67F8" : "#727272",
                              }}
                            >
                              Recommendations
                            </p>
                          </div>
                        )
                      }
                      className="tabTextColor"
                    />
                  </Tabs>
                </Box>
              </Paper>
            </Grid>
            {detailsTabs === "Notes" && (<><Grid item lg={4.9} xs={12} sm={12} md={4.9}>
              <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Device</p>
                <div className="border_padding"><p className="Poppins_16px_rem" style={{ fontWeight: "500" }}>{report.notes.device_name}</p></div>
              </Paper>
            </Grid><Grid item lg={4.9} xs={12} sm={12} md={4.9}>
                <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                  <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Failure Description</p>
                  <div className="border_padding"><img src={report.notes.icon_url} alt="button" />
                    <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign: "center" }}>{report.notes.failure_description}</p></div>
                </Paper>
              </Grid></>)}
            {detailsTabs === "Root Cause Analysis" && (<><Grid item lg={4.9} xs={12} sm={12} md={12}>
              <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Primary Cause</p>
                <div className="border_padding"><p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign:"center" }}>
                 {report.cause.primary_cause}</p></div>
              </Paper>
            </Grid>
              <Grid item lg={6} xs={12} sm={12} md={12}>
                <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                  <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Failure Insight Mapping</p>
                  <Grid container className="expanded_container">
                    {report.insight.map((item, index) => {
                      return (
                        <Grid key={index} item lg={3.7} xs={12} sm={3.7} md={3.5} className="first_grid media_600_height_auto">
                          <Paper className="first_paper media_600_height_auto" sx={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <div className="insight_button">
                              <img src={item.iconURL} alt="insight" />
                              <p className="Poppins_16px_rem" style={{ fontWeight: "500", color: "#ffff" }}>{item.title}</p>
                            </div>
                            <div className="flex_gap">
                              <Divider
                                sx={{ mt: 1 }}
                                style={{ border: "1px solid #AFAFAF" }}
                                orientation="vertical"
                              />
                              <div className="kpi-container">
                                <p className="Poppins_13px_rem" style={{fontWeight : (item.title === "Relevance") ? "700" : "400", color: item.p1 === "Critical" ? "#FF4D50" : item.p1 === "High" ? "#FFA500" :  item.p1 === "Moderate" ? "#D6D626" : "#212121"}}>{item.p1}</p>
                                <p className="Poppins_13px_rem" style={{fontWeight : (item.title === "Relevance") ? "700" : "400", color: item.p2 === "Critical" ? "#FF4D50" : item.p2 === "High" ? "#FFA500" :  item.p2 === "Moderate" ? "#D6D626" : "#212121" }}>{item.p2}</p>
                                <p className="Poppins_13px_rem" style={{fontWeight : (item.title === "Relevance") ? "700" : "400", color: item.p3 === "Critical" ? "#FF4D50" : item.p3 === "High" ? "#FFA500" :  item.p3 === "Moderate" ? "#D6D626" : "#212121" }}>{item.p3}</p>
                                <p className="Poppins_13px_rem" style={{fontWeight : (item.title === "Relevance") ? "700" : "400", color: item.p4 === "Critical" ? "#FF4D50" : item.p4 === "High" ? "#FFA500" :  item.p4 === "Moderate" ? "#D6D626" : "#212121" }}>{item.p4}</p>
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      )
                    })}

                  </Grid>
                </Paper>
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                <div className="insight_button" style={{width:"auto", cursor:"pointer"}} onClick={()=>handleDetailDiagnostic(report.faultType)}>
                              <p className="Poppins_16px_rem" style={{ fontWeight: "500", color: "#ffff" }}>Detailed Diagnostic</p>
                            </div>
                </Paper>
              </Grid>
            </>)}
            {detailsTabs === "Recommendations" && (<>
              <Grid item lg={2.9} xs={12} sm={12} md={3}>
                <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                  <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Affected System</p>
                  <div className="border_padding" style={{ flexDirection: "column", gap: "1rem" }}>
                    <img src={report.recommendations.affected_system.icon_url} alt="power" />
                    <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign: "center" }}>
                      {report.recommendations.affected_system.description}</p></div>
                </Paper>
              </Grid>
              <Grid item lg={4.5} xs={12} sm={12} md={4.5}>
                <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                  <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Faulty Component Identification</p>
                  {report.recommendations.fault_identify.map((item, index) => {
                    return (
                      <div key={index} className="border_padding" style={{ flexDirection: "column", gap: "1rem" }}>
                        <div className="flex_element"><img src={item.icon_url} alt="fault" />
                          <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign: "center" }}>
                            {item.title}</p>
                        </div>
                        <p className="Poppins_13px_rem" style={{ fontWeight: "500", textAlign: "center" }}>{item.description}</p>
                      </div>
                    )
                  })}
                </Paper>
              </Grid>
              <Grid item lg={3.5} xs={12} sm={12} md={3}>
                <Paper className="first_paper" sx={{ flexDirection: "column", gap: "0.5rem" }}>
                  <p className="Poppins_18px_rem" style={{ color: "#2F67F8", fontWeight: "500" }}>Action Steps</p>
                  <div className="border_padding" style={{ flexDirection: "column", gap: "1rem", justifyContent:"space-between" }}>
                    {report.recommendations.action_steps.map((item, index)=>{
                      const isLastItem = index === report.recommendations.action_steps.length - 1;
                      return(
                        <div key={index} className="flex_element" style={{ alignItems: "flex-start" }}>
                        <div className="column_element">
                          <img src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/step_icon.svg" alt="step" />
                          {!isLastItem && <div className="blue_line"></div>}
                        </div>
                        <p className="Poppins_16px_rem" style={{ fontWeight: "500" }}>
                          {item.step}</p>
                      </div>
                      )
                    })}
                   
                   
                  </div>
                </Paper>
              </Grid>

            </>)}
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

const ReportList = ({ reportsData }) => {
  return (
    <div className="imp_notification_block">
      <Grid container className="failure-Container">
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Raised By</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Report ID</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Resolved By</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Report Date</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Fault Type</p>
        </Grid>
        <Grid item xs={1.5} className="mobile_hide">
          <p className="Poppins_16px_rem">Actions</p>
        </Grid>
      </Grid>
      {reportsData.map((report) => (
        <ReportRow key={report.id} report={report} />
      ))}
    </div>
  );
};

const ReportFailure = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingState, setLoadingState] = useState({
    reportData: false,
  });
  // const [reportsData, setReportsData] = useState([]);
  
  const [tabValue, setTabValue] = useState("Active");

  const handleFetchError = useErrorHandler();
  const handletabVal = (val) => {
    setTabValue(val);
  };
  useEffect(() => {
    getReportData();
  }, []);

  // const getReportData = async () => {
  //   try {
  //     setLoadingState((prevState) => ({ ...prevState, reportData: true }));
  //     const res = await invService.getReportData();
  //     if (res.status === 200) {
  //       setReportsData(res.data);
  //       setLoadingState((prevState) => ({ ...prevState, reportData: false }));
  //     }
  //   } catch (error) {
  //     setLoadingState((prevState) => ({ ...prevState, reportData: false }));
  //     handleFetchError(error, "getting report data");
  //   }
  // };
  const getReportData = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, reportData: true }));
      const res = await invService.getReportsFailureData();
      if (res.status === 200) {
        // setReportsData(res.data);
        setLoadingState((prevState) => ({ ...prevState, reportData: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, reportData: false }));
      handleFetchError(error, "getting report data");
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredReports = reportsData.filter((report) =>
    report.id.toLowerCase().includes(searchQuery.toLowerCase()) || report.faultType.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="home-container">
      {loadingState.reportData && (
        <SimpleBackdrop open={loadingState.reportData} />
      )}


      <Grid container className="grid-container">
        <Grid
          item
          lg={11.9}
          xs={12}
          sm={12}
          md={11.9}
          className="paper_borderless"
        >
          <Paper
            className="powerplant-sec-paper mobile_nogap mobile_flex-col"
            sx={{ justifyContent: "space-between" }}
          >
            <div className="failure_title">
              <p className="Poppins_24px_black">Fault Analysis</p>
              <p className="report_400">Root Cause Analysis Suite</p>
            </div>
            {reportsData.length !== 0 && (<div className="search-box_smb media_992px_width_80">
              <input
                className="search_Text"
                id="search-input_report"
                value={searchQuery}
                onChange={handleSearchChange}
                type="text"
                placeholder="Search by Report ID or Fault Type"
              />
            </div>)}
          </Paper>
        </Grid>
        <Grid item lg={11.9} xs={12} sm={12} md={11.9} className="first_grid" sx={{ background: "transparent" }}>
          <Paper className="first_paper">
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => handletabVal(newValue)}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: tabValue === "Active" ? "#2FBE66" : "#FF4D50", // Change indicator color
                  },
                }}
              >
                <Tab
                  // onClick={() =>
                  //   handleAlramData(
                  //     id,
                  //     isDeviceType,
                  //     isStartTime,
                  //     isStopTime
                  //   )
                  // }
                  sx={{
                    color: tabValue === "Active" ? "#2FBE66 !important" : "#727272 !important",
                    backgroundColor: "transparent !important",
                    "&.Mui-selected": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                  value="Active"
                  className="tabTextColor"
                  label={
                    <div className="green-tab" >
                      <ToggleOn fillColor={tabValue === "Active" ? "#2FBE66" : "#727272"} />
                      <p>Active</p>
                    </div>
                  }
                />
                <Tab
                  sx={{
                    color: tabValue === "Inactive" ? "#FF4D50 !important" : "#727272 !important",
                    backgroundColor: "transparent !important",
                    "&.Mui-selected": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                  value="Inactive"
                  className="tabTextColor"
                  label={
                    <div className="green-tab" >
                      <ToggleOff fillColor={tabValue === "Inactive" ? "#FF4D50" : "#727272"} />
                      <p>Inactive</p>
                    </div>
                  }
                // onClick={() =>
                //   handleInactiveAlrams(
                //     id,
                //     isDeviceType,
                //     isStartTime,
                //     isStopTime
                //   )
                // }
                />
              </Tabs>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {reportsData.length !== 0 ? (
  tabValue === "Active" ? (
    <ReportList reportsData={filteredReports} />
  ) : (
    <ReportList reportsData={inactiveReportsData} />
  )
) : (
  <div className="no-data">
    <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
      No data is available!
    </p>
  </div>
)}

    </div>
  );
};

export default ReportFailure;