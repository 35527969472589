import React from "react";
const ToggleOn = (props) => {
    const {
        fillColor = "#2FBE66",
        ...rest                
      } = props;
  return (
    <svg
      width="23"
      height="13"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 12.5C4.83333 12.5 3.41667 11.9167 2.25 10.75C1.08333 9.58333 0.5 8.16667 0.5 6.5C0.5 4.83333 1.08333 3.41667 2.25 2.25C3.41667 1.08333 4.83333 0.5 6.5 0.5H16.5C18.1667 0.5 19.5833 1.08333 20.75 2.25C21.9167 3.41667 22.5 4.83333 22.5 6.5C22.5 8.16667 21.9167 9.58333 20.75 10.75C19.5833 11.9167 18.1667 12.5 16.5 12.5H6.5ZM6.5 10.5H16.5C17.6 10.5 18.5417 10.1083 19.325 9.325C20.1083 8.54167 20.5 7.6 20.5 6.5C20.5 5.4 20.1083 4.45833 19.325 3.675C18.5417 2.89167 17.6 2.5 16.5 2.5H6.5C5.4 2.5 4.45833 2.89167 3.675 3.675C2.89167 4.45833 2.5 5.4 2.5 6.5C2.5 7.6 2.89167 8.54167 3.675 9.325C4.45833 10.1083 5.4 10.5 6.5 10.5ZM6.5 9.5C7.33333 9.5 8.04167 9.20833 8.625 8.625C9.20833 8.04167 9.5 7.33333 9.5 6.5C9.5 5.66667 9.20833 4.95833 8.625 4.375C8.04167 3.79167 7.33333 3.5 6.5 3.5C5.66667 3.5 4.95833 3.79167 4.375 4.375C3.79167 4.95833 3.5 5.66667 3.5 6.5C3.5 7.33333 3.79167 8.04167 4.375 8.625C4.95833 9.20833 5.66667 9.5 6.5 9.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default ToggleOn;