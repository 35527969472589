import React, { useEffect, useRef, useState } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function AiControllerProductsPage() {
  const proCards = [
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixthImgOne.svg",
      title: "Advanced Control and Precision",
      description:
        "Optimizes solar energy capture by continuously monitoring motor currents and utilizing accelerometer data for precise panel positioning. It integrates a Physics-Informed Neural Network (PINN) to enhance tracking accuracy and efficiency, adapting to various conditions to maximize energy production.",
    },
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/SixthImgTwo.svg",
      title: "Versatile Tracking Modes",
      description:
        "Offers multiple operational modes including Auto, where the tracker aligns panels to a target angle; Sun Track Start, which adjusts based on solar angles; Manual, for user-controlled angle adjustments; and Stop, halting panel movement when necessary, ensuring flexibility and control.",
    },
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/SixImgThree.svg",
      title: "PINN-Optimized Tracking & Fault Prediction",
      description:
        "Leverage advanced AI and Machine Learning with the PINN-enhanced Controller for exceptional panel alignment and real-time fault prediction. The system seamlessly integrates comprehensive load monitoring and also features adaptive tracking modes that adjust to dynamic conditions. This ensures optimal performance, precise energy capture, and proactive resolution of potential issues before they impact your system.",
    },
  ];

  const keyPoints = [
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixKey1.svg",
      title: "Optimized Capture",
      description:
        "Cutting-edge AI and real-time data fine-tune panel positioning for peak solar energy absorption.",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixKey2.svg",
      title: "Adaptive Control",
      description:
        "Offers versatile tracking modes for dynamic adjustments and precise manual management.",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixKey3.svg",
      title: "Efficient Flexibility",
      description:
        "Enhances performance with responsive, intelligent tracking that adapts to diverse conditions.",
    },
  ];
  const [activeSol, setActiveSol] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const timeoutRef = useRef(null);

  const overControls = useAnimation();
  const whatControls = useAnimation();
  const craftControls = useAnimation();
  const resControls = useAnimation();

  const [overRef, inViewOver] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  useEffect(() => {
    overControls.start({
      opacity: inViewOver ? 1 : 0,
      y: inViewOver ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [overControls, inViewOver]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoSlide) {
        handleSolution(activeSol + 1);
      }
    }, 7000);

    return () => clearInterval(intervalId);
  }, [activeSol, autoSlide]);

  const isMobile = window.innerWidth <= 992;
  const isMobileOne = window.innerWidth <= 900;

  const handleHover = (hover) => {
    if (hover) {
      setAutoSlide(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      timeoutRef.current = setTimeout(() => {
        setAutoSlide(true);
      }, 1000);
    }
  };
  const handleSolution = (ind) => {
    setActiveSol(ind >= proCards.length ? 0 : ind);
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>

      <div
        className="expert_div gap_1vw flex_col our_solution_main text_cen"
        id="our_sol"
      >
        <p className="Raleway_64px" id="our_sol">
          Smarttrak AI Controller
        </p>
        <p className="Raleway_31px_dark opacity_8 c_black ">
          Boosts solar energy capture with cutting-edge precision and adaptive
          tracking, offering flexible control for peak performance in every
          scenario.
        </p>

        <div className="product_slide">
          <img
            className="powerOptix_img"
            src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideSix.png"
            alt="banner"
          />
        </div>

        <motion.div
          className="expert_div flex_col gap_1vw"
          ref={overRef}
          initial={{ opacity: 0, y: 50 }}
          animate={overControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Operational Overview</p>
          <p
            id="expertise-description"
            className="Raleway_26px_500 opacity_7 text_cen"
          >
            Enhances solar energy capture by using real-time motor and
            accelerometer data for precise panel positioning, guided by advanced
            AI models like PINN. Supports multiple tracking modes, offering
            flexibility between automated solar alignment and manual control for
            dynamic performance optimization.
          </p>
        </motion.div>
        <motion.div
          className="infoImg expert_div"
          ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}
        >
          <motion.img
            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/InfoGraphSix.png"
            alt="prod"
            className="infoGraph"
            initial={{ opacity: 0, y: 50 }}
            animate={whatControls}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
        <motion.div
          className="cards_container expert_div"
          ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Key Attributes</p>
          {proCards.map((card, index) => {
            return (
              activeSol === index && (
                <div key={index} className="pro-details">
                  <div className="expert_div img_power">
                    <img
                      src={card.img}
                      alt="product"
                      className="expertise_image"
                    />
                  </div>
                  <motion.div
                    className="expert_div gap_1vw flex_col card_power"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.7, ease: "easeInOut" }}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                  >
                    <p
                      className="Raleway_40px c_dark"
                      style={{ height: isMobileOne ? "auto" : "6vw" }}
                    >
                      {card.title}
                    </p>
                    <div
                      className="expert_div flex_col gap_1vw"
                      id="text_just"
                      style={{
                        height: isMobile ? "auto" : "14vw",
                      }}
                    >
                      <p className="Raleway_25px">{card.description}</p>
                    </div>
                    <div className="dots_container expert_div">
                      {proCards.map((folder, i) => (
                        <span
                          key={i}
                          className={`dot ${activeSol === i ? "active" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSolution(i);
                          }}
                        ></span>
                      ))}
                    </div>
                  </motion.div>
                </div>
              )
            );
          })}
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw text_cen div_width core_top"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Core Benefits</p>
          <Grid container className="key_point_grid">
            {keyPoints.map((item, index) => (
              <Grid
                item
                key={index}
                lg={3.8}
                xs={12}
                sm={8}
                md={3.5}
                className="expert_div gap_1vw"
                style={{ position: "relative" }}
              >
                <Paper className="key_cards">
                  <img src={item.keyImage} alt="keyImage" />
                  <div
                    className="expert_div gap_1vw flex_col text_cen
                "
                  >
                    <p className="Raleway_23px_700">{item.title}</p>
                    <p
                      className="Raleway_25px font_500 opacity_8"
                      style={{ height: isMobileOne ? "auto" : "6vw" }}
                    >
                      {item.description}
                    </p>
                  </div>
                </Paper>
                {!isMobileOne && (index === 0 || index === 1) && (
                  <div className="svg_ben">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="15.5"
                      viewBox="0 0 210 31"
                      fill="none"
                    >
                      <path
                        d="M2.35547 20.2998C2.35547 20.2998 45.5353 43.6132 91.0837 13.4379C136.632 -16.7373 207.795 20.2998 207.795 20.2998"
                        stroke="#00529A"
                        strokeWidth="2.7392"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="5.48 10.96"
                      />
                    </svg>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <div className="expert_div" id="margin_tb">
          <p className="Raleway_23px_700 text_cen">
            For more information, please refer to the datasheet{" "}
            <a
              className="download_url"
              target="_blank"
              rel="noopener noreferrer"
              href="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/SmarttrakAI_Solar_Tracker_Controller.pdf" // Replace with the correct path to your PDF
              download="Datasheet.pdf"
            >
              here
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AiControllerProductsPage;
