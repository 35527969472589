import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper'; 
import DynamicArrow from '../SVG/DynamicArrow';
import Divider from "@mui/material/Divider";

function Revenue(props) {
    const { customWidth, isValueData, expectedRevenue, actualRevenue, peakGenerationTime } = props;
    const revenueLoss = expectedRevenue - actualRevenue;
    const percentageLoss = ((revenueLoss / expectedRevenue) * 100).toFixed(2);

    const upwardPath = "M5.20759 0.529365C5.6079 0.00935769 6.3921 0.00935763 6.79241 0.529365L11.6888 6.89001C12.195 7.54757 11.7263 8.5 10.8964 8.5H1.10358C0.273737 8.5 -0.195026 7.54757 0.311171 6.89001L5.20759 0.529365Z";
    const downwardPath = "M6.79241 8.47063C6.3921 8.99064 5.6079 8.99064 5.20759 8.47063L0.311171 2.10999C-0.195026 1.45243 0.273737 0.5 1.10358 0.5H10.8964C11.7263 0.5 12.195 1.45243 11.6888 2.10999L6.79241 8.47063Z";
    const greenFill = "#30B900";
    const redFill = "#FF0000";

    const arrowComponent = useMemo(
        () => actualRevenue < expectedRevenue ? (
            <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
        ) : (
            <DynamicArrow pathData={upwardPath} fillColor={greenFill} width={12} height={12} />
        ),
        [actualRevenue, expectedRevenue]
    );
    return (
        <Paper className="energy-paper" sx={{justifyContent:"space-between !important", height:"100% !important", alignItems:"center"}}>
            <div className="expected_div" style={{width:"100%"}}>
                <p className="Poppins_16px_rem" style={{ fontWeight: "500" }}>Actual Revenue</p>
                <p className='Poppins_18px_rem'>{`$${actualRevenue}`}</p>
                </div>
                <div className="expected_div" style={{width:"100%"}}>
                <p className="Poppins_16px_rem" style={{ fontWeight: "500" }}>{actualRevenue < expectedRevenue ? "Revenue Loss" : "Revenue Gain"}</p>
                <div className="green-tab">
                        {arrowComponent}
                        <p className="Poppins_18px_rem" style={{ color: actualRevenue < expectedRevenue ? redFill : greenFill }}>
                            {percentageLoss || "--"}%
                        </p>
                    </div>
                </div>
            <div className="expected_div" style={{width:"100%"}}>
                <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign:"center" }}>Expected Revenue</p>
                <p className='Poppins_18px_rem'>{`$${expectedRevenue}`}</p>
                </div>
           
{/*                 
            <p className="Poppins_14px_rem" style={{ color: "#5E6064", fontWeight: "500", fontStyle: "italic", textAlign: "center" }}>
            {`$${revenueLoss.toFixed(2)} loss (${percentageLoss}% loss over a ${peakGenerationTime} hour peak generation period).`}
            </p> */}
        </Paper>
    );
}

export default Revenue;
