import React, { useState } from "react";
import SensorIcon from "../SVG/SensorIcon";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InverterIcon from "../SVG/InverterIcon";
import Tooltip from "@mui/material/Tooltip";
function StatusGrid(props) {
  const { statuses, title, legendName } = props;
  const [filteredStatus, setFilteredStatus] = useState(null); 

 
  const handleFilter = (status) => {
    setFilteredStatus(status === filteredStatus ? null : status); 
  };

  const filteredStatuses = filteredStatus
    ? statuses.filter((item) => item.status === filteredStatus)
    : statuses;

  return (
    <div className="full_width">
      <div className="space-betwn_div media_600_flex_col">
        <p className="Poppins_18px_rem">{title}</p>
        <div className="center_center_flex" style={{ gap: "1rem" }}>
          {legendName.map((item, index) => (
            <div
              key={index}
              className="expert_div gap_point_3rem"
              onClick={() => handleFilter(item.name)}
              style={{
                cursor: "pointer",
                opacity: filteredStatus && filteredStatus !== item.name ? 0.5 : 1, 
                fontWeight: filteredStatus === item.name ? "bold" : "normal", 
              }}
            >
              {item.icon === "InverterIcon" ? (
                <InverterIcon fillColor={item.fillColor} />
              ) : (
                <SensorIcon fillColor={item.fillColor} />
              )}
              <p className="Poppins_11px_rem" style={{ fontWeight: "500" }}>
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Grid
        container
        className="grid-container"
        sx={{ backgroundColor: "#ffff", padding: "1rem", justifyContent: "center", gap: "1rem" }}
      >

        {filteredStatuses.map((item, index) => (
          <Grid
            item
            key={index}
            lg={2.6}
            xs={5.5}
            sm={3.5}
            md={2.6}
            className="exc-graph-grid"
            sx={{
              border: "none !important",
              backgroundColor:
                item.status === "Operational" || item.status === "Up-to-Date"
                  ? "#A2CF9F"
                  : item.status === "Action Needed" ||
                    item.status === "Unsupported"
                    ? "#FF4D50"
                    : item.status === "Outdated"
                      ? "orange"
                      : "#A2CF9F",
            }}
          >
            {(item.status === "Action Needed" && index === 4) ? (<Tooltip
              title="Sensor requires recalibration"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#ffff",
                    color: "#128BF7",
                    fontSize: "0.875rem",
                    boxShadow: "0px 0px 4px 0px #00000040",
                    fontFamily: "Poppins",
                    fontWeight: "500"
                  },
                },
              }}
            >
              <Paper
                className="exc-graph-paper gap_point_3rem padding_point_5rem"
                sx={{ justifyContent: "center !important" }}
              >
                {item.icon === "InverterIcon" ? (
                  <InverterIcon fillColor="#212121" />
                ) : (
                  <SensorIcon fillColor="#212121" />
                )}
                <p className="Poppins_11px_rem font_500">{item.name}</p>
              </Paper>
            </Tooltip>) : (item.status === "Action Needed" && index === 10) ?
              (<Tooltip
                title="Disconnected since 10:30 AM"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#ffff",
                      color: "#128BF7",
                      fontSize: "0.875rem",
                      boxShadow: "0px 0px 4px 0px #00000040",
                      fontFamily: "Poppins",
                      fontWeight: "500"
                    },
                  },
                }}
              >
                <Paper
                  className="exc-graph-paper gap_point_3rem padding_point_5rem"
                  sx={{ justifyContent: "center !important" }}
                >
                  {item.icon === "InverterIcon" ? (
                    <InverterIcon fillColor="#212121" />
                  ) : (
                    <SensorIcon fillColor="#212121" />
                  )}
                  <p className="Poppins_11px_rem font_500">{item.name}</p>
                </Paper>
              </Tooltip>)
              :
              (<Paper
                className="exc-graph-paper gap_point_3rem padding_point_5rem"
                sx={{ justifyContent: "center !important" }}
              >
                {item.icon === "InverterIcon" ? (
                  <InverterIcon fillColor="#212121" />
                ) : (
                  <SensorIcon fillColor="#212121" />
                )}
                <p className="Poppins_11px_rem font_500">{item.name}</p>
              </Paper>)
            }
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default StatusGrid;
