import React, { useEffect, useState } from "react";
import "../../ExecutiveDashboard/ExceutiveDashboard.scss";
import WeatherBoard from "../../../Common/WeatherBoard";
import "../../../Common/FontSizes.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import MinMaxWeather from "../../../Common/MinMaxWeather";
import SingleLineTempGraph from "../../../Graphs/SingleLineTempGraph";
import "./WeatherDashbrd.scss";
import useErrorHandler from "../../../../utils/errorHandler";
import MultiDateLineGraph from "../../../Graphs/MultiDateLineGraph";
import { useMobile } from "../../../Context/MobileContext/MobileContext";
const invService = new CosmicInverterService();
function WeatherDashbrd() {

  const [windDateVal, setWindDateVal] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isWeatherData, setIsWeatherData] = useState([]);
  const [panelDeviceId, setPanelDeviceId] = useState(null);
  const { isMobileAndTab, isDesktopView } = useMobile();
  const [weatherMaxVal, setWeatherMaxVal] = useState({});
  const [isStartTimeWind, setIsStartTimeWind] = useState("");
  const [isStopTimeWind, setIsStopTimeWind] = useState("");
  const [isStartTimeModule, setIsStartTimeModule] = useState("");
  const [isStopTimeModule, setIsStopTimeModule] = useState("");

  const [isStartTimeIrradiance, setIsStartTimeIrradiance] = useState("");
  const [isStopTimeIrradiance, setIsStopTimeIrradiance] = useState("");

  const [isStartTimeAbsolute, setIsStartTimeAbsolute] = useState("");
  const [isStopTimeAbsolute, setIsStopTimeAbsolute] = useState("");

  const [isStartTimeRelative, setIsStartTimeRelative] = useState("");
  const [isStopTimeRelative, setIsStopTimeRelative] = useState("");
  const [deviceId, setDeviceId] = useState(0);
  const [moduleTemperature, setModuleTemperature] = useState([]);
  const [relativeHumidity, setRelativeHumidity] = useState([]);

  const [absoluteHumidity, setAbsoluteHumidity] = useState([]);

  const [perfCompareVal, setPerfCompareVal] = useState([]);
  const [isStartTimeDeviceCompare, setIsStartTimeDeviceCompare] = useState("");
  const [isStopTimeDeviceCompare, setIsStopTimeDeviceCompare] = useState("");
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  
  const [isFieldCompareVal, setIsFieldCompareVal] = useState(deviceFieldValue[0]);

  const [irrandianceOne, setIrrandianceOne] = useState([]);
  const [peakValue, setPeakValue] = useState([]);
  const [weatherInfoData, setWeatherInfoData] = useState({});
  const [loadingState, setLoadingState] = useState({
    weatherParams: false,
    windSpeed: false,
    airTemp: false,
    irraidance: false,
    relhumidity: false,
    abshumidity: false,
    deviceid: false,
    forecast: false,
    weatherData: false,
    moduleTemp: false,
  });
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
    graph5: false,
    graph6: false,
  });
  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);
  const handleFetchError = useErrorHandler();
  const fetchApiDuration = 2 * 60 * 1000;
  useEffect(() => {
    if (id) {
      const callApis = () => {
        handlePlantDevices(id);
        handleWeatherParameters(id);
        handleWeatherInfo(id);
      };
      callApis();
      const intervalId = setInterval(() => {
        callApis();
      }, fetchApiDuration); 
  
      return () => clearInterval(intervalId);
    }
  }, [id]);
  useEffect(() => {
    handleFieldsList(deviceId);
  }, [deviceId]);
  useEffect(() => {
    if (deviceFieldValue.length > 0) {
      setIsFieldCompareVal(deviceFieldValue[0]);
    }
  }, [deviceFieldValue]);
  useEffect(() => {
    if (!isFieldCompareVal) {
      setLoadingState((prevState) => ({
        ...prevState,
        deviceCompare: false, 
      }));
    }
  }, [isFieldCompareVal]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeWind && isStopTimeWind) {
        handleWindSpeed(deviceId, isStartTimeWind, isStopTimeWind);
      } else {
        const fetchData = () => {
          handleWindSpeed(deviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeWind]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeDeviceCompare && isStopTimeDeviceCompare) {
        handleDeviceCompare(
          deviceId,
          isFieldCompareVal,
          isStartTimeDeviceCompare,
          isStopTimeDeviceCompare
        );
      }
    }
  }, [isFieldCompareVal, isStopTimeDeviceCompare]);

  useEffect(() => {
    const fetchComparisonData = () => {
      if (deviceId) {
        handleDeviceCompare(deviceId, isFieldCompareVal);
      }
    };
    fetchComparisonData();
    const comparisonIntervalId = setInterval(
      fetchComparisonData,
      5 * 60 * 1000
    );
    return () => clearInterval(comparisonIntervalId);
  }, [isFieldCompareVal, deviceId]);

  useEffect(() => {
    if (panelDeviceId) {
      if (isStartTimeModule && isStopTimeModule) {
        handleModuleTemp(panelDeviceId, isStartTimeModule, isStopTimeModule);
      } else {
        const fetchData = () => {
          handleModuleTemp(panelDeviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeModule]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeAbsolute && isStopTimeAbsolute) {
        handleAbsoluteHumidity(
          deviceId,
          isStartTimeAbsolute,
          isStopTimeAbsolute
        );
      } else {
        const fetchData = () => {
          handleAbsoluteHumidity(deviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeAbsolute]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeRelative && isStopTimeRelative) {
        handleRelativeHumidity(
          deviceId,
          isStartTimeRelative,
          isStopTimeRelative
        );
      } else {
        const fetchData = () => {
          handleRelativeHumidity(deviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeRelative]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeIrradiance && isStopTimeIrradiance) {
        handleIrradiance(deviceId, isStartTimeIrradiance, isStopTimeIrradiance);
      } else {
        const fetchData = () => {
          handleIrradiance(deviceId);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeIrradiance]);

  const weatherInfo = [
    {
      title: "Humidity",
      temp: `${weatherInfoData?.humidity ? weatherInfoData?.humidity : "--"}%`,

      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Vector.svg",
    },
    {
      title: "Wind Speed",
      temp: `${
        weatherInfoData?.windSpeed ? weatherInfoData?.windSpeed : "--"
      }km/h`,
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Wind.svg",
    },
    {
      title: "Air Temp",
      temp: `${
        weatherInfoData?.airTemperature ? weatherInfoData?.airTemperature : "--"
      }°C`,
      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Cloud+Wind.svg",
    },
    {
      title: "Irradiance",

      temp: `${
        isWeatherData[0]?.device_data?.irradiance
          ? isWeatherData[0]?.device_data?.irradiance
          : "--"
      } W/m²`,
      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Solarirradiance.svg",
    },
  ];

 

  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };

  const handlePlantDevices = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceid: true }));

      const res = await invService.getPlantDevices(id, "wms");
      if (res.status === 200) {
        setDeviceId(res.data[0].id);
        handleWeatherMaxValues(res.data[0].id);
        handleWindSpeed(res.data[0].id);
        handleRelativeHumidity(res.data[0].id);
        handleAbsoluteHumidity(res.data[0].id);
        handleIrradiance(res.data[0].id);
      }
      setLoadingState((prevState) => ({ ...prevState, deviceid: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, deviceid: false }));
      handleFetchError(error, "plant device data");
    }
  };
  const handleWindSpeed = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, windSpeed: true }));

      const res = await invService.getWeatherWindSpeed(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.wind_speed !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Wind Speed": item.wind_speed,
          }));
        setWindDateVal(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, windSpeed: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, windSpeed: false }));
      handleFetchError(error, "wind data not found");
    }
  };


 const handleModuleTemp = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: true }));

      const res = await invService.getModuleTemp(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.module_temperature_degree_C !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Module Temperature": item.module_temperature_degree_C,
          }));
        setModuleTemperature(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: false }));
      handleFetchError(error, "module temperature data not found");
    }
  };
  const handleAbsoluteHumidity = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, abshumidity: true }));

      const res = await invService.getWeatherAbsoluteHumidity(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.absolute_humidity_act !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Absolute Humidity": item.absolute_humidity_act,
          }));
        setAbsoluteHumidity(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, abshumidity: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, abshumidity: false }));
      handleFetchError(error, "absolute humidity data not found");
    }
  };

  const handleFieldsList = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFieldsWMS("wms", device_id);
      if (res.status === 200) {
        setDeviceFieldValue(res.data);

        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "device fields");
    }
  };

  const handleDeviceCompare = async (id, fields, start_time, end_time) => {
    try {
      if (!fields || fields.trim() === "" || fields === undefined || fields === null) {
        setPerfCompareVal([]);
      } else {
        setLoadingState((prevState) => ({ ...prevState, deviceCompare: true }));
        const res = await invService.getDeviceCompareWMS(
          "wms",
          id,
          fields,
          start_time,
          end_time
        );
        if (res.status === 200) {
          const formattedData = res.data.map((item) => {
            const formattedItem = { timestamp_edge: item.timestamp_edge };
            Object.keys(item).forEach((field) => {
              if (field !== "timestamp_edge" && field !== "device_name") {
                const formattedField = field
                  .replace("_", " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase());
                let fieldValue = item[field];
                if (typeof fieldValue === "number") {
                  fieldValue = fieldValue.toFixed(2);
                }
                formattedItem[formattedField] = fieldValue;
              }
            });
            return formattedItem;
          });
          setPerfCompareVal(formattedData);
          setLoadingState((prevState) => ({
            ...prevState,
            deviceCompare: false,
          }));
        }
      }
    } catch (error) {
      setPerfCompareVal([]);

      setLoadingState((prevState) => ({ ...prevState, deviceCompare: false }));
      handleFetchError(error, "Device Fields Comparison");
    }
  };

  const handleRelativeHumidity = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, relhumidity: true }));

      const res = await invService.getWeatherRelativeHumidity(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.relative_humidity_act !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Relative Humidity": item.relative_humidity_act,
          }));
        setRelativeHumidity(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, relhumidity: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, relhumidity: false }));
      handleFetchError(error, "relative humidity data not found");
    }
  };
  const handleIrradiance = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, irraidance: true }));

      const res = await invService.getWeatherIrradiance(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const peakValueArray = [];
        const formattedData = res.data
          // .filter((item) => item.irradiance !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            Irradiance: item.irradiance,
          }));

        setIrrandianceOne(formattedData);
        const totalIrradiance = formattedData.reduce(
          (sum, data) => sum + data.Irradiance,
          0
        );
        const peakValue = {
          peak_active_power: totalIrradiance / formattedData.length,
          peak_name: "Average Irradiance :",
          peak_unit: " W/m²",
        };
        peakValueArray.push(peakValue);
        setPeakValue(peakValueArray);
      }
      setLoadingState((prevState) => ({ ...prevState, irraidance: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, irraidance: false }));
      handleFetchError(error, "Irradiance data not found");
    }
  };

  const handleWeatherParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));

      const resWMS = await invService.getDeviceLevelData(id, "wms");
      if (resWMS.status === 200) {
        setIsWeatherData(resWMS.data);
      }
      const resPlant = await invService.getDeviceLevelData(id, "panel");
      if (resPlant.status === 200) {
        setPanelDeviceId(resPlant.data[0].device_data.device_id);
        handleModuleTemp(resPlant.data[0].device_data.device_id);
        // handleAmbientTemp(resPlant.data[0].device_data.device_id);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      // handleFetchError(error, "weather data not found");
    }
  };
  const handleWeatherInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));

      const res = await invService.getWeatherInfo(id);
      if (res.status === 200) {
        const timestamp = res.data.dt * 1000;
        const date = new Date(timestamp);

        const month = date.toLocaleString("default", { month: "long" });
        const dayAbbreviation = date.toLocaleString("en-US", {
          weekday: "long",
        });
        setWeatherInfoData({
          weatherConditions: res.data.weather[0].main,
          weatherUrl: res.data.weather[0].icon,

          temperature: res.data.main.feels_like,
          airTemperature: res.data.main.temp,

          minTemp: res.data.main.temp_min,
          maxTemp: res.data.main.temp_max,
          humidity: res.data.main.humidity,
          windSpeed: res.data.wind.speed,
          day: dayAbbreviation,
          date: date.getDate(),
          month: month,
        });
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      handleFetchError(error, "weatherdata data not found");
    }
  };
  const handleWeatherMaxValues = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherParams: true }));

      const res = await invService.getWeatherMaxValues(device_id);
      if (res.status === 200) {
        setWeatherMaxVal(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherParams: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherParams: false }));
      handleFetchError(error, "weatherdata data not found");
    }
  };

  return (
    <div className="home-container" style={{ position: "relative" }}>
      {loadingState.deviceid && <SimpleBackdrop open={loadingState.deviceid} />}
      <Grid container className="weatherDash-container">
        <Grid
          item
          lg={9.6}
          xs={12}
          sm={12}
          md={9}
          className="scrolling-grid-exc"
          sx={{flexDirection:
            fullScreenStates.graph1 || fullScreenStates.graph2 ||
            fullScreenStates.graph3 || fullScreenStates.graph4 || 
            fullScreenStates.graph5 || fullScreenStates.graph6
             ? "row" : "column"}}
        >
          <MinMaxWeather
            weatherData={weatherMaxVal}
            loader={loadingState.weatherParams}
          />

          <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph1 ? "fixed" : "relative",
              top: fullScreenStates.graph1 ? "9vh" : "auto",
              width: fullScreenStates.graph1 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph1 ? "92vh" : "auto",
              margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
              zIndex: fullScreenStates.graph1 ? 999 : "auto",
              background: "#fff",
            }}
          >
            <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph1 ? "100%" : "auto"}}>
              <SingleLineTempGraph
                isValueData={windDateVal}
                titleText="Wind Speed"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeWind}
                StopTimer={setIsStopTimeWind}
                yAxisText="Wind Speed (km/h)"
                legendName="Wind Speed"
                indicateUnit="km/h"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeWind ? isStartTimeWind : new Date(),
                    endDate: isStopTimeWind ? isStopTimeWind : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
              isDesktopView={isDesktopView}
              isFullScreen={fullScreenStates.graph1}
              toggleFullScreen={() => toggleFullScreen('graph1')}
              />
            </Paper>
            {loadingState.windSpeed && (
              <SimpleBackdrop open={loadingState.windSpeed} />
            )}
          </Grid>

          {/* <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
          >
            <Paper className="exc-graph-paper">
              <SingleLineTempGraph
                isValueData={ambientTemp}
                titleText="Ambient Temperature"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeAmbient}
                StopTimer={setIsStopTimeAmbient}
                yAxisText="Ambient Temperature (°C)"
                legendName="Ambient Temperature "
                indicateUnit="(°C)"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeAmbient
                      ? isStartTimeAmbient
                      : new Date(),
                    endDate: isStopTimeAmbient ? isStopTimeAmbient : new Date(),
                    key: "selection",
                  },
                ]}
              />
            </Paper>
            {loadingState.airTemp && (
              <SimpleBackdrop open={loadingState.airTemp} />
            )}
          </Grid> */}

          <Grid
            item
            lg={12}
            xs={12}
            md={12}
            sm={11.9}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph2 ? "fixed" : "relative",
              top: fullScreenStates.graph2 ? "9vh" : "auto",
              width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph2 ? "92vh" : "auto",
              margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
              zIndex: fullScreenStates.graph2 ? 999 : "auto",
              background: "#fff",
            }}
          >
            <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph2 ? "100%" : "auto"}}>
              <SingleLineTempGraph
                isValueData={irrandianceOne}
                titleText="Irradiance"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeIrradiance}
                StopTimer={setIsStopTimeIrradiance}
                yAxisText="Irradiance(W/m²)"
                legendName="Irradiance"
                indicateUnit="W/m²"
                calenderClick="on"
                ispeakValue={peakValue}
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeIrradiance
                      ? isStartTimeIrradiance
                      : new Date(),
                    endDate: isStopTimeIrradiance
                      ? isStopTimeIrradiance
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView}
                isFullScreen={fullScreenStates.graph2}
                toggleFullScreen={() => toggleFullScreen('graph2')}
              />
            </Paper>
            {loadingState.irraidance && (
              <SimpleBackdrop open={loadingState.irraidance} />
            )}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph3 ? "fixed" : "relative",
              top: fullScreenStates.graph3 ? "9vh" : "auto",
              width: fullScreenStates.graph3 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph3 ? "92vh" : "auto",
              margin: fullScreenStates.graph3 ? "-1vh !important" : "0vh",
              zIndex: fullScreenStates.graph3 ? 999 : "auto",
              background: "#fff",
            }}
          >
            <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph3 ? "100%" : "auto"}}>
              <SingleLineTempGraph
                isValueData={relativeHumidity}
                titleText="Relative Humidity"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeRelative}
                StopTimer={setIsStopTimeRelative}
                yAxisText="Relative Humidity"
                legendName="Relative Humidity"
                indicateUnit="%"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeRelative
                      ? isStartTimeRelative
                      : new Date(),
                    endDate: isStopTimeRelative
                      ? isStopTimeRelative
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView}
                isFullScreen={fullScreenStates.graph3}
                toggleFullScreen={() => toggleFullScreen('graph3')}
              />
            </Paper>
            {loadingState.relhumidity && (
              <SimpleBackdrop open={loadingState.relhumidity} />
            )}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph4 ? "fixed" : "relative",
              top: fullScreenStates.graph4 ? "9vh" : "auto",
              width: fullScreenStates.graph4 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph4 ? "92vh" : "auto",
              margin: fullScreenStates.graph4 ? "-1vh !important" : "0vh",
              zIndex: fullScreenStates.graph4 ? 999 : "auto",
              background: "#fff",
            }}
          >
            <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph4 ? "100%" : "auto"}}>
              <SingleLineTempGraph
                isValueData={absoluteHumidity}
                titleText="Absolute Humidity"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeAbsolute}
                StopTimer={setIsStopTimeAbsolute}
                yAxisText="Absolute Humidity"
                legendName="Absolute Humidity"
                indicateUnit="g/m3"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeAbsolute
                      ? isStartTimeAbsolute
                      : new Date(),
                    endDate: isStopTimeAbsolute
                      ? isStopTimeAbsolute
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView}
                isFullScreen={fullScreenStates.graph4}
                toggleFullScreen={() => toggleFullScreen('graph4')}
              />
            </Paper>
            {loadingState.abshumidity && (
              <SimpleBackdrop open={loadingState.abshumidity} />
            )}
          </Grid>
          {moduleTemperature.length > 0 && (
            <Grid
              item
              lg={12}
              xs={12}
              md={11.9}
              sm={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph5 ? "fixed" : "relative",
                top: fullScreenStates.graph5 ? "9vh" : "auto",
                width: fullScreenStates.graph5 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph5 ? "92vh" : "auto",
                margin: fullScreenStates.graph5 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph5 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph5 ? "100%" : "auto"}}>
                <SingleLineTempGraph
                  isValueData={moduleTemperature}
                  titleText="Module Temperature"
                  openDrawerValue={setDrawerOpen}
                  StartTimer={setIsStartTimeModule}
                  StopTimer={setIsStopTimeModule}
                  yAxisText="Module Temperature (°C)"
                  legendName="Module Temperature "
                  indicateUnit="(°C)"
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeModule
                        ? isStartTimeModule
                        : new Date(),
                      endDate: isStopTimeModule ? isStopTimeModule : new Date(),
                      key: "selection",
                    },
                  ]}
                  isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView}
                isFullScreen={fullScreenStates.graph5}
                toggleFullScreen={() => toggleFullScreen('graph5')}
                />
              </Paper>
              {loadingState.moduleTemp && (
                <SimpleBackdrop open={loadingState.moduleTemp} />
              )}
            </Grid>
          )}
          <Grid item lg={11.9} xs={12} md={11.9} className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph6 ? "fixed" : "relative",
            top: fullScreenStates.graph6 ? "9vh" : "auto",
            width: fullScreenStates.graph6 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph6 ? "92vh" : "auto",
            margin: fullScreenStates.graph6 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph6 ? 999 : "auto",
            background: "#fff",
          }}>
            <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph6 ? "100%" : "auto"}}>
            {isFieldCompareVal || perfCompareVal.length !== 0 || deviceFieldValue.length !== 0 ? ( 
              <MultiDateLineGraph
                isValueData={perfCompareVal.length === 0 || deviceFieldValue.length === 0 ? [] : perfCompareVal}
                StartTimer={setIsStartTimeDeviceCompare}
                StopTimer={setIsStopTimeDeviceCompare}
                openDrawerValue={setDrawerOpen}
                titleText="Weather Fields Comparison"
                legendName=""
                calenderClick="on"
                selectedType={setIsFieldCompareVal}
                fieldsDropdata={deviceFieldValue}
                defaultValue={isFieldCompareVal ? isFieldCompareVal : deviceFieldValue[0] }
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeDeviceCompare
                      ? isStartTimeDeviceCompare
                      : new Date(),
                    endDate: isStopTimeDeviceCompare
                      ? isStopTimeDeviceCompare
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView}
                isFullScreen={fullScreenStates.graph6}
                toggleFullScreen={() => toggleFullScreen('graph6')}
              />
            ) :  <SimpleBackdrop open={loadingState.deviceCompare} />} 
              {loadingState.deviceCompare && (
                <SimpleBackdrop open={loadingState.deviceCompare} />
              )}
              {loadingState.fieldList && (
                <SimpleBackdrop open={loadingState.fieldList} />
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid
          item
          lg={2.3}
          xs={12}
          sm={12}
          md={2.8}
          className="weather_grid_weather"
        >
          <Paper className="notification-paper-one">
            <WeatherBoard
              weatherData={weatherInfo}
              weatherParam={weatherInfoData}
              weaHeight="90vh"
            />
          </Paper>
          {loadingState.weatherData && (
            <SimpleBackdrop open={loadingState.weatherData} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default WeatherDashbrd;
