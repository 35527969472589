import React from 'react';

const SensorIcon = (props) => {
    const {
        fillColor = '#111111',
        ...rest
    } = props;
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84 12.2438C2.12 11.5105 1.55 10.6605 1.13 9.69383C0.71 8.72716 0.5 7.6905 0.5 6.58383C0.5 5.46383 0.71 4.4205 1.13 3.45383C1.55 2.48716 2.12 1.64383 2.84 0.923828L3.98 2.06383C3.39333 2.65049 2.93333 3.33049 2.6 4.10383C2.26667 4.87716 2.1 5.70383 2.1 6.58383C2.1 7.47716 2.26667 8.31049 2.6 9.08383C2.93333 9.85716 3.39333 10.5305 3.98 11.1038L2.84 12.2438ZM5.1 9.98383C4.67333 9.54383 4.33333 9.03383 4.08 8.45383C3.82667 7.87383 3.7 7.25049 3.7 6.58383C3.7 5.90383 3.82667 5.27383 4.08 4.69383C4.33333 4.11383 4.67333 3.61049 5.1 3.18383L6.24 4.32383C5.94667 4.61716 5.71667 4.95716 5.55 5.34383C5.38333 5.73049 5.3 6.14383 5.3 6.58383C5.3 7.02383 5.38333 7.43716 5.55 7.82383C5.71667 8.21049 5.94667 8.5505 6.24 8.84383L5.1 9.98383ZM8.5 8.18383C8.06 8.18383 7.68333 8.02716 7.37 7.71383C7.05667 7.4005 6.9 7.02383 6.9 6.58383C6.9 6.14383 7.05667 5.76716 7.37 5.45383C7.68333 5.14049 8.06 4.98383 8.5 4.98383C8.94 4.98383 9.31667 5.14049 9.63 5.45383C9.94333 5.76716 10.1 6.14383 10.1 6.58383C10.1 7.02383 9.94333 7.4005 9.63 7.71383C9.31667 8.02716 8.94 8.18383 8.5 8.18383ZM11.9 9.98383L10.76 8.84383C11.0533 8.5505 11.2833 8.21049 11.45 7.82383C11.6167 7.43716 11.7 7.02383 11.7 6.58383C11.7 6.14383 11.6167 5.73049 11.45 5.34383C11.2833 4.95716 11.0533 4.61716 10.76 4.32383L11.9 3.18383C12.3267 3.61049 12.6667 4.11383 12.92 4.69383C13.1733 5.27383 13.3 5.90383 13.3 6.58383C13.3 7.25049 13.1733 7.87383 12.92 8.45383C12.6667 9.03383 12.3267 9.54383 11.9 9.98383ZM14.16 12.2438L13.02 11.1038C13.6067 10.5172 14.0667 9.83716 14.4 9.06383C14.7333 8.29049 14.9 7.46383 14.9 6.58383C14.9 5.6905 14.7333 4.85716 14.4 4.08383C14.0667 3.31049 13.6067 2.63716 13.02 2.06383L14.16 0.923828C14.88 1.64383 15.45 2.48716 15.87 3.45383C16.29 4.4205 16.5 5.46383 16.5 6.58383C16.5 7.6905 16.29 8.72716 15.87 9.69383C15.45 10.6605 14.88 11.5105 14.16 12.2438Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SensorIcon;
