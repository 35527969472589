import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Select from "react-select";
import Dropdown from "react-dropdown";
import Divider from "@mui/material/Divider";

const MultiDateLineGraph = (props) => {
  const {
    isValueData,
    titleText,
    legendName,
    calenderClick,
    fieldsDropdata,
    durationDropData,
    durationTypes,
    defaultValue,selectedDateRangeProp,
    isMobileAndTab,
    isDesktopView,
    isFullScreen, toggleFullScreen
  } = props;

  const [isOptionType, setIsOptionType] = useState([defaultValue]);

  const [series, setSeries] = useState([]);
  const [xaxisData, setXaxisData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isValueData && isValueData.length > 0) {
      const xaxisLabels = [];
      const groupedData = {};
      const allTimes = Array.from({ length: 24 }, (_, i) =>
        i < 10 ? "0" + i + ":00" : i + ":00"
      );

      // Iterate through each data item and group by date and time
      isValueData.forEach((item) => {
        const date = item.timestamp_edge.split("T")[0];
        const time = item.timestamp_edge.split("T")[1].substring(0, 5); // Extract hour:minute
        if (!groupedData[date]) {
          groupedData[date] = {};
        }

        for (const field in item) {
          if (field !== "timestamp_edge") {
            if (!groupedData[date][field]) {
              groupedData[date][field] = [];
            }
            if (!groupedData[date][field][time]) {
              groupedData[date][field][time] = []; // Initialize time slot with empty array
            }
            groupedData[date][field][time].push(item[field]);
          }
        }
      });

      // Create series for each unique date and field combination
      const seriesData = [];
      Object.keys(groupedData).forEach((date) => {
        const dataForDate = [];
        Object.keys(groupedData[date]).forEach((field) => {
          const fieldData = groupedData[date][field];
          const summedData = allTimes.map((time) =>
            fieldData[time] ? fieldData[time][0] : null
          ); // Use null for missing values
          dataForDate.push({
            name: `${date} - ${field}`,
            data: allTimes.map((time, index) => ({
              x: time,
              y: summedData[index],
            })), // Include null values
          });
        });
        seriesData.push(...dataForDate); // Push the series data for the current date to the main array
      });
      setSeries(seriesData);
      // Set x-axis labels to include all times
      xaxisLabels.push(...allTimes);
      setXaxisData(xaxisLabels);
    }
  }, [isValueData]);

  const [options, setOptions] = useState({
    chart: { type: "area" },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      categories: xaxisData,
      // type: "datetime",

      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
      },
    },
    yaxis: {
      title: {
        text: titleText,
        style: {
          colors: "#000000",
        },
      },
      labels: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2);
        },
        style: {
          colors: "#000000",
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2);
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    // grid: {
    //   show: false,
    // },
    grid: {
      show: true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      // position: "top",
      showForSingleSeries: true,
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2);
        },
      },
    },
    toolbar: {
      show: false,
    },
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); 
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);
  useEffect(() => {
    const yAxisConfigs = isOptionType.map((field, index) => ({
      opposite: index % 2 === 0, // Alternate between left and right alignment
      title: {
        text:
          field === "negative_active_energy_kWh"
            ? "Cumulative Energy Export kWh"
            : field === "negative_active_energy_kVARh"
            ? "Cumulative Energy Export kVARh"
            : field
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()),
      },
      labels: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2);
        },
        style: {
          colors: "#000000",
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2);
          },
        },
      },
    }));

    setOptions((prevOptions) => ({
      ...prevOptions,
      yaxis: yAxisConfigs,
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisData,
        // type: "datetime",

        labels: {
          datetimeUTC: false,

          style: {
            colors: "#000000",
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm", // Apply the desired format
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2);
          },
        },
      },
      // yaxis: {
      //   ...prevOptions.yaxis,
      //   // text: titleText,
      //   style: {
      //     colors: isFullScreen ? "white" : "#000000",
      //   },

      //   labels: {
      //     ...prevOptions.yaxis.labels,
      //     formatter: function (value) {
      //       if (value === null || typeof value === "undefined") {
      //         return ""; // Return an empty string if value is undefined
      //       }
      //         return value.toFixed(2);
      //     },
      //     style: {
      //       colors: isFullScreen ? "white" : "#000000",
      //     },
      //     y: {
      //       formatter: function (value) {
      //         if (value === null || typeof value === "undefined") {
      //   return ""; // Return an empty string if value is undefined
      // }
      //   return value.toFixed(2);
      //       },
      //     },
      //   },
      // },
      legend: {
        ...prevOptions.legend,
        labels: {
          colors: "#000000",
        },
      },
    }));
    props.selectedType && props.selectedType(isOptionType.join(","));
  }, [
    isOptionType,
    isValueData,
    isFullScreen,
    xaxisData,
    // series,
    legendName,
    titleText,
  ]);

  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };
  
  

  
  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    
    props.openDrawerValue(openDrawer);
  };

  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    
    props.openDrawerValue(openDrawer);
  };
  const [isDurationType, setIsDurationType] = useState("");

  const handleChangeDeviceFields = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setIsOptionType(selectedValues);
    const yAxisConfigs = selectedValues.map((field, index) => ({
      opposite: index % 2 === 0, // Alternate between left and right alignment
      title: {
        text:
          field === "negative_active_energy_kWh"
            ? "Cumulative Energy Export kWh"
            : field === "negative_active_energy_kVARh"
            ? "Cumulative Energy Export kVARh"
            : field
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()),
      },
      labels: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2);
        },
        style: {
          colors: "#000000",
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2);
          },
        },
      },
    }));

    // Set the y-axis configuration in the chart options
    setOptions((prevOptions) => ({
      ...prevOptions,
      yaxis: yAxisConfigs,
    }));

    // Pass selected values to parent component or update state as needed
    props.selectedType && props.selectedType(selectedValues.join(","));
  };
  const handleChangeDeviceDuration = (selectedOptions) => {
    setIsDurationType(selectedOptions.value);
    props.durationTypes(selectedOptions.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: "#212121" }}
          >
            {titleText}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         {fieldsDropdata.length > 0 && <div
            style={{ minWidth: "14vw", cursor: "pointer" }}
            id="exc-linegraph-no-padding"
          >
            <Select
              className="recommendations-text"
              options={fieldsDropdata.map((field) => ({
                value: field,
                label:
                  field === "negative_active_energy_kWh"
                    ? "Cumulative Energy Export kWh"
                    : field === "negative_active_energy_kVARh"
                    ? "Cumulative Energy Export kVARh"
                    : field
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase()),
              }))}
              isMulti
              onChange={handleChangeDeviceFields}
              value={isOptionType.map((field) => ({
                value: field,
                label: field
                  ? field
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                  : "",
              }))}
              placeholder="Select"
            />
          </div>}
          {/* {durationTypes && durationDropData && (
            <div
              style={{ width: "auto", minWidth: "1.6vw", cursor: "pointer" }}
              id="exc-linegraph"
            >
              <Dropdown
                className="recommendations-text"
                options={durationDropData.map((field) =>
                  field
                    ? field
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                    : ""
                )}
                onChange={handleChangeDeviceDuration}
                value={isDurationType}
                placeholder="Select"
              />
            </div>
          )} */}
          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>
          <Tooltip title="Calendar" arrow>
            <CalendarMonthIcon
              onClick={handleTimings}
             className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={toggleFullScreen}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon
                 className='icon-css'
                />
              ) : (
                <FullscreenIcon
                 className='icon-css'
                />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}
      <div
        style={{
          height: isValueData.length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
          display: isValueData.length === 0 && "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {isValueData && isValueData.length === 0 ? (
          <div>
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <Chart options={options} series={series} type="area" height={(isFullScreen && isMobileAndTab) ? "300" : (isFullScreen && isDesktopView) ? "500" : "350"} />
        )}
      </div>
    </div>
  );
};

export default MultiDateLineGraph;
