import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import "./MFM.scss";
import "../../../Common/FontSizes.scss";
import BarPlotForMFM from "../../../Graphs/BarPlotForMFM";
import SingleLineTempGraph from "../../../Graphs/SingleLineTempGraph";
import MultiDateLineGraph from "../../../Graphs/MultiDateLineGraph";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import TableNoSearchBar from "../../../Common/TableNoSearchBar";
import useErrorHandler from "../../../../utils/errorHandler";
import TimestampFormatter from "../../../Common/TimestampFormatter";
import ButtonSlider from "../../../Common/ButtonSlider";
import { useMobile } from "../../../Context/MobileContext/MobileContext";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import EnergyGenerated from "../../../Common/EnergyGenerated";
import EnergyData from "../../../Common/EnergyData";

const invService = new CosmicInverterService();
function MFMDetails() {
  const [mfmData, setIsMfmData] = useState({});
  const history = useHistory();
  const location = useLocation()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const mfmfirstField = location?.state?.firstField;

  
 const { isMobileAndTab, isDesktopView } = useMobile();
  const mfmDataSet = [
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: mfmData?.device_data?.phase_A_RMS_current_A
        ? `${mfmData?.device_data?.phase_A_RMS_current_A.toFixed(2)} A`
        : "0 A",
      title: "Phase A RMS Current(A)",
    },
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: mfmData.device_data?.phase_B_RMS_current_A
        ? `${mfmData?.device_data?.phase_B_RMS_current_A.toFixed(2)} A`
        : "0 A",
      title: "Phase B RMS Current(A)",
    },
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: mfmData?.device_data?.phase_C_RMS_current_A
        ? `${mfmData?.device_data?.phase_C_RMS_current_A.toFixed(2)} A`
        : "0 A",
      title: "Phase C RMS Current(A)",
    },
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: mfmData?.device_data?.earth_RMS_current_A
        ? `${mfmData?.device_data?.earth_RMS_current_A.toFixed(2)} A`
        : "0 A",
      title: "Earth RMS Current(A)",
    },
  ];
  const currentData = [
    {
      subtitle: "R-Phase Current & THD",
      value: mfmData?.device_data?.phase_A_RMS_current_A
        ? `${mfmData?.device_data?.phase_A_RMS_current_A.toFixed(2)} A`
        : "0 A",
    },
    {
      subtitle: "Y-Phase Current & THD",
      value: mfmData?.device_data?.phase_B_RMS_current_A
        ? `${mfmData?.device_data?.phase_B_RMS_current_A.toFixed(2)} A`
        : "0 A",
    },
    {
      subtitle: "B-Phase Current & THD",
      value: mfmData?.device_data?.phase_C_RMS_current_A
        ? `${mfmData?.device_data?.phase_C_RMS_current_A.toFixed(2)} A`
        : "0 A",
    },
  ];
  const voltageData = [
    {
      subtitle: "R-Phase Voltage & THD",
      value: mfmData?.device_data?.phase_A_RMS_voltage_V
        ? `${mfmData?.device_data?.phase_A_RMS_voltage_V.toFixed(2)} V`
        : "0 V",
    },
    {
      subtitle: "Y-Phase Voltage & THD",
      value: mfmData?.device_data?.phase_B_RMS_voltage_V
        ? `${mfmData?.device_data?.phase_B_RMS_voltage_V.toFixed(2)} V`
        : "0 V",
    },
    {
      subtitle: "B-Phase Voltage & THD",
      value: mfmData?.device_data?.phase_C_RMS_voltage_V
        ? `${mfmData?.device_data?.phase_C_RMS_voltage_V.toFixed(2)} V`
        : "0 V",
    },
  ];
  const exportData = [
    {
      subtitle: "Active Energy (kWh)",
      value: mfmData?.device_data?.negative_active_energy_kWh
        ? `${mfmData?.device_data?.negative_active_energy_kWh.toFixed(2)} kWh`
        : "--",
    },
    {
      subtitle: "Reactive Energy (kVARh)",
      value: mfmData?.device_data?.negative_reactive_energy_kVARh
        ? `${mfmData?.device_data?.negative_reactive_energy_kVARh.toFixed(
            2
          )} kVARh`
        : "--",
    },
  ];
  const importData = [
    {
      subtitle: "Active Energy (kWh)",
      value: mfmData?.device_data?.positive_active_energy_kWh
        ? `${mfmData?.device_data?.positive_active_energy_kWh.toFixed(2)} kWh`
        : "--",
    },
    {
      subtitle: "Reactive Energy (kVARh)",
      value: mfmData?.device_data?.positive_reactive_energy_kVARh
        ? `${mfmData?.device_data?.positive_reactive_energy_kVARh.toFixed(
            2
          )} kVARh`
        : "--",
    },
  ];

  useEffect(() => {
    handleFieldsList();
  }, []);

  const [energyGraphData, setEnergyGraphData] = useState([]);
  const [mfmFieldCompare, setMfmFieldCompare] = useState([]);
  const [durationType, setDurationType] = useState(["hour", "minute"]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [isStartTimeMfmFieldCompare, setIsStartTimeMfmFieldCompare] =
    useState("");
  const [isStopTimeMfmFieldCompare, setIsStopTimeMfmFieldCompare] =
    useState("");
  const [deviceID, setDeviceId] = useState(location?.state?.mfmDeviceData?.device_details.id);
  const [activeMfm, setActiveMfm] = useState(
    location?.state?.mfmDeviceData
  );

  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
  });
  const [isStartTimeBarEnergy, setIsStartTimeBarEnergy] = useState("");
  const [isStopTimeBarEnergy, setIsStopTimeBarEnergy] = useState("");
  const [isMfmRMSCurrent, setIsMfmRMSCurrent] = useState([]);
  const [isMfmRMSVoltage, setIsMfmRMSVoltage] = useState([]);
  const [isStartTimeRMSCurrent, setIsStartTimeRMSCurrent] = useState("");
  const [isStopTimeRMSCurrent, setIsStopTimeRMSCurrent] = useState("");
  const [isStartTimeRMSVoltage, setIsStartTimeRMSVoltage] = useState("");
  const [isStopTimeRMSVoltage, setIsStopTimeRMSVoltage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isFieldCompareVal, setIsFieldCompareVal] = useState(
    mfmfirstField ? mfmfirstField : ""
  );
  const [stringDuration, setStringDuration] = useState("hour");
  const [energyDuration, setEnergyDuration] = useState("h");
  const [loadingState, setLoadingState] = useState({
    table: false,
    fieldList: false,
    graph1: false,
    mfmPara: false,
    current: false,
    voltage: false,
    compare: false,
    power: false,
    mfmDatas: false
  })
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [powerarray, setPowerArray] = useState({});
  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = 7; 
  const [mfmDataArray, setMfmDataArray] = useState([]);
  const handleFetchError = useErrorHandler();
  const fetchApiDuration = 2 * 60 * 1000;
  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);
  useEffect(() => {
    if (id) {
      handleMfmData(id);
    }
  }, [id]);
  
  useEffect(() => {
    if (deviceID) {
      const callApis = () => {
        handleEnergyMeterGraph(deviceID, energyDuration);
        handleMfmFieldCompare(deviceID, isFieldCompareVal, stringDuration);
        handleMfmRMSCurrent(deviceID);
        handleMfmRMSVoltage(deviceID);
        handleDeviceTable(deviceID);
        handleMfmParameters(deviceID);
        handleInverterPowerInfo(deviceID);
      };
      callApis();
      const intervalId = setInterval(() => {
        callApis();
      }, fetchApiDuration); 
      return () => clearInterval(intervalId);
    }
  }, [deviceID, energyDuration, isFieldCompareVal, stringDuration]);

  useEffect(() => {
    const fetchComparisonData = () => {
      if (deviceID) {
        handleMfmFieldCompare(deviceID, isFieldCompareVal, stringDuration);
      }
    };
    fetchComparisonData();
    // const comparisonIntervalId = setInterval(fetchComparisonData, 5 * 60 * 1000);
    // return () => clearInterval(comparisonIntervalId);
  }, [isFieldCompareVal, stringDuration]);
  useEffect(() => {
    if (deviceID) {
      if (isStartTimeMfmFieldCompare && isStopTimeMfmFieldCompare) {
        handleMfmFieldCompare(
          deviceID,
          isFieldCompareVal,
          isStartTimeMfmFieldCompare,
          isStopTimeMfmFieldCompare,
          stringDuration.toLowerCase()
        );
      }
    }
  }, [isFieldCompareVal, isStopTimeMfmFieldCompare, stringDuration]);
  useEffect(() => {
    if (deviceID) {
      if ((isStartTimeBarEnergy && isStopTimeBarEnergy) || energyDuration) {
        handleEnergyMeterGraph(
          deviceID,
          energyDuration,
          isStartTimeBarEnergy,
          isStopTimeBarEnergy
        );
      }
    }
  }, [isStopTimeBarEnergy, energyDuration]);
  useEffect(() => {
    if (deviceID) {
      if (isStartTimeRMSCurrent && isStopTimeRMSCurrent) {
        handleMfmRMSCurrent(
          deviceID,
          isStartTimeRMSCurrent,
          isStopTimeRMSCurrent
        );
      }
    }
  }, [isStopTimeRMSCurrent]);
  useEffect(() => {
    if (deviceID) {
      if (isStartTimeRMSVoltage && isStopTimeRMSVoltage) {
        handleMfmRMSVoltage(
          deviceID,
          isStartTimeRMSVoltage,
          isStopTimeRMSVoltage
        );
      }
    }
  }, [isStopTimeRMSVoltage]);

  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };

  const handleAvtiveMFM = (mfm, id, index) => {
    if(index !== 0){
    setActiveMfm(mfm);
    setDeviceId(id);
    handleEnergyMeterGraph(id, energyDuration);
    handleMfmFieldCompare(id, isFieldCompareVal, stringDuration);
    handleMfmRMSCurrent(id);
    handleMfmRMSVoltage(id);
    handleDeviceTable(id);
    handleMfmParameters(id);
    handleInverterPowerInfo(id);
    } else{
      history.push("/iot-home/dev-monitoring/mfm")
     }
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const startIndex = currentPage * itemsPerPage;
  let visibleMFMs = mfmDataArray.slice(startIndex, startIndex + itemsPerPage);
   if (currentPage === 0) {
    visibleMFMs = [
      {
        device_details:{name: "MFMs Dashboard"},
      },
      ...visibleMFMs,
    ];
  }
  const handleMfmData = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmDatas: true }));
      const res = await invService.getDeviceLevelData(id, "mfm");
      if (res.status === 200) {
        setMfmDataArray(res.data);
        setLoadingState((prevState) => ({ ...prevState, mfmDatas: false }));
      }
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, mfmDatas: false }));
      handleFetchError(err, "mfm List");
    }
  };

  const handleDeviceTable = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, table: true }));
      const res = await invService.getDeviceTable(device_id, "mfm");
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedDate = item["Date(YYYY-MM-DD)"]
            ? item["Date(YYYY-MM-DD)"].split("T")[0]
            : "--";
          const formattedItem = { ...item, "Date(YYYY-MM-DD)": formattedDate };

          // Iterate through keys to apply toFixed(2) on numeric values
          for (let key in formattedItem) {
            if (typeof formattedItem[key] === "number") {
              formattedItem[key] = formattedItem[key].toFixed(2);
            }
          }

          return formattedItem;
        });

        setDeviceTableData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, table: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, table: false }));
      handleFetchError(error, "mfm table List");
    }
  };

  const handleFieldsList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));

      const res = await invService.getListDeviceFields("mfm");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "mfm field List");
    }
  };
  const handleEnergyMeterGraph = async (
    id,
    stringDuration,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, graph1: true }));
      const res = await invService.getEnergyMeterGraph(
        id,
        stringDuration,
        start_time,
        end_time,
        "mfm"
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const { timestamp_edge, "Energy Generation kWh": energyGeneration } =
            item;
          return { timestamp_edge, "Energy Generation kWh": energyGeneration };
        });

        setEnergyGraphData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, graph1: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, graph1: false }));
      handleFetchError(error, "mfm energy data");
    }
  };

  const handleMfmParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmPara: true }));
      const res = await invService.getParticularIdData(id, "mfm");
      if (res.status === 200) {
        setIsMfmData(res.data);
        setLoadingState((prevState) => ({ ...prevState, mfmPara: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, mfmPara: false }));
      handleFetchError(error, "mfm parameters data");
    }
  };

  const handleMfmRMSCurrent = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, current: true }));
      const res = await invService.getEnergyMeterRmsCurrent(
        id,
        start_time,
        end_time,
        "mfm",
        "phase_A_RMS_current_A,phase_B_RMS_current_A,phase_C_RMS_current_A"
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setIsMfmRMSCurrent(formattedData);
        setLoadingState((prevState) => ({ ...prevState, current: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, current: false }));
      handleFetchError(error, "mfm current data");
    }
  };
  const handleMfmRMSVoltage = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, voltage: true }));
      const res = await invService.getEnergyMeterRmsVoltage(
        id,
        start_time,
        end_time,
        "mfm",
        "phase_A_RMS_voltage_V,phase_B_RMS_voltage_V,phase_C_RMS_voltage_V"
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setIsMfmRMSVoltage(formattedData);
        setLoadingState((prevState) => ({ ...prevState, voltage: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, voltage: false }));
      handleFetchError(error, "mfm voltage data");
    }
  };

  const handleMfmFieldCompare = async (
    id,
    fields,
    start_time,
    end_time,
    filter = stringDuration.toLowerCase()
  ) => {
    try {
      if (fields.length === 0) {
        setMfmFieldCompare([]);
      } else {
        setLoadingState((prevState) => ({ ...prevState, compare: true }));

        const res = await invService.getMfmCompareFields(
          id,
          fields,
          start_time,
          end_time,
          filter,
          "mfm",
          false
        );
        if (res.status === 200) {
          const formattedData = res.data.map((item) => {
            const formattedItem = { timestamp_edge: item.timestamp_edge };
            Object.keys(item).forEach((field) => {
              if (field !== "timestamp_edge" && field !== "device_name") {
                const formattedField = field
                  .replace("_", " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase());
                formattedItem[formattedField] = item[field];
              }
            });
            return formattedItem;
          });
          setMfmFieldCompare(formattedData);

          setLoadingState((prevState) => ({ ...prevState, compare: false }));
        }
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, compare: false }));
      handleFetchError(error, "mfm field compare data");
    }
  };
  const handleInverterPowerInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, power: true }));
      const res = await invService.getInverterOverview(id, "mfm");
      if (res.status === 200) {
        setPowerArray(res.data);
        setLoadingState((prevState) => ({ ...prevState, power: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, power: false }));
      handleFetchError(error, "MFM Info");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
   
  return (
    <div className="home-container">
      <Grid container className="grid-container">
      <Grid
          container
          className="inv_names_container"
          sx={{
            padding: "1rem !important",
            backgroundColor: "white !important",
            border: "0.0625rem solid #D8D8D8 !important",
            borderRadius: "0.75rem !important",
            position:"relative"
          }}
        >
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="only_flex-start"
           
          >
            <Grid container className="inv-names_grid">
              {visibleMFMs.length > 0 ? (
                <>
          <ButtonSlider 
          isValueData={visibleMFMs} 
          deviceId={deviceID} 
          handleInvDeatil={handleAvtiveMFM} 
          itemsPerPage={itemsPerPage} />
        </>
              ) 
              : (
                <div className="no-data">
                   <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                    No data is available!
                  </p>
             
                </div>
              )}
            </Grid>
            { loadingState.mfmDatas && (
                  <SimpleBackdrop open={loadingState.mfmDatas} />
                )}
          </Grid>
        </Grid>
        
        <Grid item lg={12} xs={12} sm={12} md={12} className="heading_grid">
          <Grid container className="grid-container">
            <Grid
              item
              lg={5.1}
              xs={12}
              sm={12}
              md={12}
              className="mfm_grid_item"
            >
              <Paper
                className="key_value_container"
                sx={{ height: "100% !important", position:"relative" }}
              >
                <div className="flex-start_space-between">
                  <p className="Poppins_36px_rem" style={{ color: "#2F67F8" }}>
                    {activeMfm.device_details?.name ? activeMfm.device_details?.name : "--"}
                  </p>
                  <div
                    className="invstatus-container"
                    style={{
                      borderRadius: "0.6rem",
                      border: "1px solid #D8D8D8",
                    }}
                  >
                    <div
                      className="green-status"
                      style={{
                        backgroundColor:
                          activeMfm?.device_details?.status === true ? "green" : "red",
                      flexDirection: activeMfm?.device_details?.status === true ? "row-reverse" : "row"

                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            activeMfm?.device_details?.status === true
                              ? "rgb(113 213 69)"
                              : "red",
                          border:
                            activeMfm?.device_details?.status === true
                              ? "1px solid #21ad3d"
                              : "1px solid #c3392f",
                          width: "0.5rem",
                          height: "0.5rem",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <p className="raleway_8px_rem">
                        {activeMfm?.device_details?.status === true ? "ON" : "OFF"}
                      </p>
                    </div>
                    <p className="Poppins_16px_rem">Status</p>
                  </div>
                </div>
                <div className="space-btn-center">
                  <div className="mfm-one-div">
                    <div className="flex-start-nogap">
                      <p className="raleway_14px_rem">
                        Total Energy Generation
                      </p>
                      <div className="center_div_gap">
                        <p className="raleway_32px_rem">
                          {powerarray.total_yield_energy_kWh !== null &&
                          powerarray.total_yield_energy_kWh !== undefined
                            ? `${powerarray.total_yield_energy_kWh.toFixed(2)} `
                            : "--"}
                          <span className="Poppins_18px_rem">
                            {powerarray.unit && powerarray.unit}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mfm-one-div"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className="flex-start-nogap">
                      <p className="raleway_14px_rem">Last Updated Timestamp</p>
                      <div className="center_div_gap">
                        <p className="Poppins_18px_rem">
                          { mfmData?.device_data?.timestamp_edge?<TimestampFormatter dateString={mfmData?.device_data?.timestamp_edge} />:"--"}
                        </p>
                      </div>
                      <div className="center_div_gap">
                      <p className="Poppins_14px_rem" style={{ color: "#5E6064", fontWeight: "500", fontStyle: "italic", textAlign: "center" }}>
                 (Data refreshed every 2 mins)
            </p>
                      </div>
                    </div>
                  </div>
                </div>
                {loadingState.power && (
                  <SimpleBackdrop open={loadingState.power} />
                )}
              </Paper>
            </Grid>

            <Grid
              item
              lg={6.8}
              xs={12}
              sm={12}
              md={12}
              className="center_center_column"
             
            >
              <Grid container className="grid-container" sx={{position:"relative"}}>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  className="center_center_flex_gap"
                >
                  <Paper className="gen-power_paper">
                    <p
                      className="Poppins_24px_rem"
                      style={{ color: "#212121", textAlign: "center" }}
                    >
                      Generated Energy
                    </p>
                  </Paper>
                </Grid>

                <Grid
                  item
                  lg={3.5}
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  className="energy-grid"
                >
                  <Paper className="energy-paper">
                    <EnergyData
                      ACPercent={
                        powerarray?.today_increase_percent?.percentage !==
                          null &&
                        powerarray?.today_increase_percent?.percentage !==
                          undefined
                          ? powerarray.today_increase_percent.percentage.toFixed(
                              1
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.today_increase_percent?.value !== null &&
                        powerarray?.today_increase_percent?.value !== undefined
                          ? powerarray?.today_increase_percent?.value.toFixed(1)
                          : "--"
                      }
                      ACEnergy={
                        powerarray.today_generated_energy_kWh !== null &&
                        powerarray.today_generated_energy_kWh !== undefined
                          ? powerarray.today_generated_energy_kWh.toFixed(1)
                          : "--"
                      }
                      unit="kWh"
                      title="Today’s Generation"
                      colorCode="#00529A"
                    />
                  </Paper>
                
                </Grid>
                <Divider className="divider-style" orientation="vertical" />
                <Grid
                  item
                  lg={3.5}
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  className="energy-grid"
                >
                  <Paper className="energy-paper">
                    <EnergyData
                      ACPercent={
                        powerarray?.yesterday_increase_percent?.percentage !==
                          null &&
                        powerarray?.yesterday_increase_percent?.percentage !==
                          undefined
                          ? powerarray?.yesterday_increase_percent?.percentage.toFixed(
                              1
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.yesterday_increase_percent?.value !==
                          null &&
                        powerarray?.yesterday_increase_percent?.value !==
                          undefined
                          ? powerarray?.yesterday_increase_percent?.value.toFixed(
                              1
                            )
                          : "--"
                      }
                      ACEnergy={
                        powerarray.yesterdays_generated_energy_kWh !== null &&
                        powerarray.yesterdays_generated_energy_kWh !== undefined
                          ? powerarray.yesterdays_generated_energy_kWh.toFixed(
                              1
                            )
                          : "--"
                      }
                      unit="kWh"
                      title="Yesterday’s Generation"
                      colorCode="#FDA737"
                    />
                  </Paper>
                </Grid>
                <Divider className="divider-style" orientation="vertical" />
                <Grid
                  item
                  lg={3.5}
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  className="energy-grid"
                >
                  <Paper className="energy-paper">
                    <EnergyData
                      ACPercent={
                        powerarray?.monthly_increase_percent?.percentage !==
                          null &&
                        powerarray?.monthly_increase_percent?.percentage !==
                          undefined
                          ? powerarray?.monthly_increase_percent?.percentage.toFixed(
                              1
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.monthly_increase_percent?.value !== null &&
                        powerarray?.monthly_increase_percent?.value !==
                          undefined
                          ? powerarray?.monthly_increase_percent?.value.toFixed(
                              1
                            )
                          : "--"
                      }
                      ACEnergy={
                        powerarray.past_30days_generated_energy_kWh
                          ? powerarray.past_30days_generated_energy_kWh.toFixed(
                              1
                            )
                          : "--"
                      }
                      unit="kWh"
                      title="Monthly Generation"
                      colorCode="#04724D"
                    />
                  </Paper>
                </Grid>
                {loadingState.power && (
                  <SimpleBackdrop open={loadingState.power} />
                )}
              </Grid>
               {/* <EnergyGenerated customWidth="50%" dropdownWidth="50%" /> */}
             
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="grid-container">
        <Grid item lg={12} xs={12} sm={12} md={12} className="heading_grid">
          <Paper
            sx={{
              width: "100% !important",
              padding: "10px",
              backgroundColor: "transparent",
              boxShadow: "none !important",
            }}
          >
            <p className="Poppins_32px_rem" style={{ color: "#2F67F8" }}>
              MFM Performance Summary
            </p>
          </Paper>
        </Grid>

        <Grid item lg={12} xs={12} sm={12} md={12} className="mfm_dataSet">
        <Grid container className="grid-container">
          {mfmDataSet.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                lg={2.95}
                xs={11.9}
                sm={5.9}
                md={5.9}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  background: "transparent !important",
                  borderRadius: "1.875rem",
                  position:"relative"
                }}
              >
                <Paper className="four_grids_container">
                  <div className="icon_container">
                    <img src={item.iconImg} alt="gen" className="icon_3rem" />
                  </div>
                  <div className="value_container">
                    <p className="Poppins_32px_rem">{item.value}</p>
                    <p className="raleway_18px_rem">{item.title}</p>
                  </div>
                </Paper>
                {loadingState.mfmPara && (
                  <SimpleBackdrop open={loadingState.mfmPara} />
                )}
              </Grid>
            );
          })}
          </Grid>
        </Grid>
        <Grid item lg={5.95} xs={12} sm={12} md={6} className="mfm_data_grid">
          <p className="raleway_24px_rem" style={{ fontWeight: "700" }}>
            Current with THD
          </p>

          <Grid container className="grid-container_center">
            {currentData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={3.7}
                  xs={12}
                  sm={3.8}
                  md={3.8}
                  className="mfm_grid_item"
                >
                  <Paper className="key_value_container">
                    <p
                      className="raleway_16px_rem"
                      style={{ fontWeight: "700", color: "black" }}
                    >
                      {item.subtitle}
                    </p>
                    <p className="Poppins_18px_rem" id="blue_value">
                      {item.value}
                    </p>
                  </Paper>
                 
                </Grid>
              );
            })}
          </Grid>
          {loadingState.mfmPara && (
                  <SimpleBackdrop open={loadingState.mfmPara} />
                )}
        </Grid>
        <Grid item lg={5.95} xs={12} sm={12} md={5.9} className="mfm_data_grid">
          <p className="raleway_24px_rem" style={{ fontWeight: "700" }}>
            Voltage with THD
          </p>

          <Grid container className="grid-container_center">
            {voltageData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={3.7}
                  xs={11.9}
                  sm={3.8}
                  md={3.8}
                  className="mfm_grid_item"
                >
                  <Paper className="key_value_container">
                    <p
                      className="raleway_16px_rem"
                      style={{ fontWeight: "700", color: "black" }}
                    >
                      {item.subtitle}
                    </p>
                    <p className="Poppins_18px_rem" id="blue_value">
                      {item.value}
                    </p>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
          {loadingState.mfmPara && (
                  <SimpleBackdrop open={loadingState.mfmPara} />
                )}
        </Grid>
        <Grid item lg={6} xs={12} sm={12} md={6} className="mfm_data_grid">
          <div className="metric-box">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerMetrics.svg"
              alt="metric"
              className="metric-icon"
            />
            <p className="raleway_24px_rem" style={{ fontWeight: "700" }}>
              Cumulative Import Energy
            </p>
          </div>
          <Grid container className="grid-container_center">
            {importData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={5.7}
                  xs={12}
                  sm={5.8}
                  md={5.7}
                  className="mfm_grid_item"
                >
                  <Paper className="key_value_container">
                    <p
                      className="raleway_16px_rem"
                      style={{ fontWeight: "700", color: "black" }}
                    >
                      {item.subtitle}
                    </p>
                    <p className="Poppins_18px_rem" id="blue_value">
                      {item.value}
                    </p>
                  </Paper>
                </Grid>
              );
            })}         
          </Grid>
          {loadingState.mfmPara && (
                  <SimpleBackdrop open={loadingState.mfmPara} />
                )}
        </Grid>
        <Grid item lg={5.9} xs={12} sm={12} md={5.9} className="mfm_data_grid">
          <div className="metric-box">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerMetrics.svg"
              alt="metric"
              className="metric-icon"
            />
            <p className="raleway_24px_rem" style={{ fontWeight: "700" }}>
              Cumulative Export Energy
            </p>
          </div>
          <Grid container className="grid-container_center">
            {exportData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={5.7}
                  xs={12}
                  sm={5.8}
                  md={5.7}
                  className="mfm_grid_item"
                >
                  <Paper className="key_value_container">
                    <p
                      className="raleway_16px_rem"
                      style={{ fontWeight: "700", color: "black" }}
                    >
                      {item.subtitle}
                    </p>
                    <p className="Poppins_18px_rem" id="blue_value">
                      {item.value}
                    </p>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
          {loadingState.mfmPara && (
                  <SimpleBackdrop open={loadingState.mfmPara} />
                )}
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="notification_grid"
        >
          <Paper className="notification_paper">
            <div className="imp_notification_block">
              <p className="raleway_24px_rem" style={{ color: "#212121" }}>
                Important Notifications
              </p>
            </div>
          </Paper>
          <Divider className="divider_nomargin" />
          {/* {NotificationData.map((ele, index) => ( */}
          <Paper
            // key={index}
            className="notification_paper"
          >
            <div className="imp_notification_block">
              <p
                className="raleway_18px_rem"
                style={{
                  fontWeight: "700",
                }}
              >
                No Notification yet
              </p>
            </div>
            {/* <div
            style={{
              padding: "1vh 2vw",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: "0.5vw",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "baseline",
              }}
            >
              <WarningRoundedIcon style={{ color: "#FFA287" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "baseline",
              }}
            >
              <p className="Poppins_18px_rem">
                {ele.title}
                <span style={{ fontWeight: "400" }}>
                  &nbsp;{ele.disc}
                </span>
              </p>
            </div>
          </div> */}
          </Paper>
          {/* ))} */}
        </Grid>

        <Grid item lg={12} xs={12} md={12} className="graph_grid"
         sx={{
          position: fullScreenStates.graph1 ? "fixed" : "relative",
          top: fullScreenStates.graph1 ? "9vh" : "auto",
          width: fullScreenStates.graph1 ? "-webkit-fill-available !important" : "auto",
          height: fullScreenStates.graph1 ? "92vh" : "auto",
          margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          zIndex: fullScreenStates.graph1 ? 999 : "auto",
          background: "#fff",
        }}
        >
          <Paper className="graph_paper" sx={{height:fullScreenStates.graph1 ? "100%" : "auto"}}>
            <BarPlotForMFM
              isValueData={energyGraphData}
              StartTimer={setIsStartTimeBarEnergy}
              StopTimer={setIsStopTimeBarEnergy}
              openDrawerValue={setDrawerOpen}
              indicateUnit="kWh"
              titleText="Energy Meter"
              yAxisText="Generated Energy(kWh)"
              calenderClick="on"
              tooltipName="Energy Generation"
              stringDurationFun={setEnergyDuration}
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeBarEnergy
                    ? isStartTimeBarEnergy
                    : new Date(),
                  endDate: isStopTimeBarEnergy
                    ? isStopTimeBarEnergy
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isDesktopView={isDesktopView}
              isFullScreen={fullScreenStates.graph1}
              toggleFullScreen={() => toggleFullScreen('graph1')}
            />
          </Paper>
          {loadingState.graph1 && (
                  <SimpleBackdrop open={loadingState.graph1} />
                )}
        </Grid>
        <Grid item lg={fullScreenStates.graph2 ? 12 : 6} xs={12} md={fullScreenStates.graph2 ? 12 : 6} className="graph_grid"
         sx={{
          position: fullScreenStates.graph2 ? "fixed" : "relative",
          top: fullScreenStates.graph2 ? "9vh" : "auto",
          width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
          height: fullScreenStates.graph2 ? "92vh" : "auto",
          margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
          zIndex: fullScreenStates.graph2 ? 999 : "auto",
          background: "#fff",
        }}>
          <Paper className="graph_paper" sx={{height:fullScreenStates.graph2 ? "100%" : "auto"}}>
            <SingleLineTempGraph
              isValueData={isMfmRMSCurrent}
              StartTimer={setIsStartTimeRMSCurrent}
              StopTimer={setIsStopTimeRMSCurrent}
              openDrawerValue={setDrawerOpen}
              yAxisText="RMS Current"
              titleText="RMS Current"
              indicateUnit="A"
              calenderClick="on"
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeRMSCurrent
                    ? isStartTimeRMSCurrent
                    : new Date(),
                  endDate: isStopTimeRMSCurrent
                    ? isStopTimeRMSCurrent
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isDesktopView={isDesktopView}
              isFullScreen={fullScreenStates.graph2}
              toggleFullScreen={() => toggleFullScreen('graph2')}
            />
          </Paper>
          {loadingState.current && (
                  <SimpleBackdrop open={loadingState.current} />
                )}
        </Grid>
        <Grid item lg={fullScreenStates.graph3 ? 12 : 5.9} xs={12} md={fullScreenStates.graph3 ? 12 : 5.9} className="graph_grid"
        sx={{
          position: fullScreenStates.graph3 ? "fixed" : "relative",
          top: fullScreenStates.graph3 ? "9vh" : "auto",
          width: fullScreenStates.graph3 ? "-webkit-fill-available !important" : "auto",
          height: fullScreenStates.graph3 ? "92vh" : "auto",
          margin: fullScreenStates.graph3 ? "-1vh !important" : "0vh",
          zIndex: fullScreenStates.graph3 ? 999 : "auto",
          background: "#fff",
        }}>
          <Paper className="graph_paper" sx={{height:fullScreenStates.graph3 ? "100%" : "auto"}}>
            <SingleLineTempGraph
              isValueData={isMfmRMSVoltage}
              StartTimer={setIsStartTimeRMSVoltage}
              StopTimer={setIsStopTimeRMSVoltage}
              openDrawerValue={setDrawerOpen}
              yAxisText="RMS Voltage"
              titleText="RMS Voltage"
              indicateUnit="V"
              calenderClick="on"
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeRMSVoltage
                    ? isStartTimeRMSVoltage
                    : new Date(),
                  endDate: isStopTimeRMSVoltage
                    ? isStopTimeRMSVoltage
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isDesktopView={isDesktopView}
              isFullScreen={fullScreenStates.graph3}
              toggleFullScreen={() => toggleFullScreen('graph3')}
            />
          </Paper>
          {loadingState.voltage && (
                  <SimpleBackdrop open={loadingState.voltage} />
                )}
        </Grid>
        <Grid item lg={12} xs={12} md={12} className="graph_grid"
        sx={{
          position: fullScreenStates.graph4 ? "fixed" : "relative",
          top: fullScreenStates.graph4 ? "9vh" : "auto",
          width: fullScreenStates.graph4 ? "-webkit-fill-available !important" : "auto",
          height: fullScreenStates.graph4 ? "92vh" : "auto",
          margin: fullScreenStates.graph4 ? "-1vh !important" : "0vh",
          zIndex: fullScreenStates.graph4 ? 999 : "auto",
          background: "#fff",
        }}>
          <Paper className="graph_paper" sx={{height:fullScreenStates.graph4 ? "100%" : "auto"}}>
            <MultiDateLineGraph
              isValueData={mfmFieldCompare}
              StartTimer={setIsStartTimeMfmFieldCompare}
              StopTimer={setIsStopTimeMfmFieldCompare}
              openDrawerValue={setDrawerOpen}
              titleText="MFM Comparison"
              legendName=""
              calenderClick="on"
              selectedType={setIsFieldCompareVal}
              fieldsDropdata={deviceFieldValue}
              durationTypes={setStringDuration}
              durationDropData={durationType}
              defaultValue={isFieldCompareVal}
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeMfmFieldCompare
                    ? isStartTimeMfmFieldCompare
                    : new Date(),
                  endDate: isStopTimeMfmFieldCompare
                    ? isStopTimeMfmFieldCompare
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isDesktopView={isDesktopView}
              isFullScreen={fullScreenStates.graph4}
              toggleFullScreen={() => toggleFullScreen('graph4')}
            />
          </Paper>
          {loadingState.compare && (
                  <SimpleBackdrop open={loadingState.compare} />
                )}
        </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="inv_table_container"
        >
          <TableNoSearchBar
            columns={deviceTableData}
            data={deviceTableData}
            page={page}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
          />
           {loadingState.table && (
                  <SimpleBackdrop open={loadingState.table} />
                )}
        </Grid>
        </Grid>
    </div>
  );
}

export default MFMDetails;
