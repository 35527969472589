import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useHistory, useLocation } from "react-router-dom";
import "./SideBarIot.scss";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DashboardIcon from "../SVG/DashboardIcon";
import MonitoringIcon from "../SVG/MonitoringIcon";
import ReportsIcon from "../SVG/ReportsIcon";
import AlarmssIcon from "../SVG/AlarmsIcon";
import ReportFailureIcon from "../SVG/ReportsFailureIcon";
import AbtIcon from "../SVG/AbtIcon";
import PlantViewIcon from "../SVG/PlantViewIcon";
import InverterIcon from "../SVG/InverterIcon";
import ThreeDIcon from "../SVG/ThreeDIcon";
import TrackerIcon from "../SVG/TrackerIcon";
import VCBPanelIcon from "../SVG/VCBPanelIcon";
import HTPanelIcon from "../SVG/HTPanelIcon";

import { useMFMContext } from "../Context/MobileContext/MFMContext";
import FileIcon from "../SVG/FileIcon";

import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";


const SideBar = (props) => {
  const { height } = props;
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const drawerRef = useRef(null);
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: isOpenSidebar ? "max-content" : "5rem",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  useEffect(() => {
    const handleResize = () => {
     setIsMobile(window.innerWidth <= 992);
   };
   handleResize();
   window.addEventListener("resize", handleResize);
   return () => {
     window.removeEventListener("resize", handleResize);
   };
 }, []);

  useEffect(() => {
    setActiveTabBasedOnLocation(location.pathname);
  }, [location.pathname]);
 
  const setActiveTabBasedOnLocation = (pathname) => {
    const tabMapping = {
      "/iot-home": "Executive Dashboard",
      "/iot-home/reports": "Reporting Hub",
      "/iot-home/alarms": "Early Warning System",
      "/iot-home/fault_analysis": "Fault Analysis",
      "/iot-home/fault_analysis/detailed_diagnostic": "Fault Analysis",
      "/iot-home/plant-layout": "Plant Layout",
      "/iot-home/three-d-twin": "3D Digital Twin",
      "/iot-home/file-upload": "AI Doc Explorer",

      "/iot-home/dev-monitoring/inv-dashboard": "Device Monitoring",
      "/iot-home/dev-monitoring/mfm": "Device Monitoring",
      "/iot-home/dev-monitoring/mfm-data": "Device Monitoring",
      "/iot-home/dev-monitoring/abt": "Device Monitoring",
      "/iot-home/dev-monitoring/smb": "Device Monitoring",
      "/iot-home/dev-monitoring/smb-details": "Device Monitoring",
      "/iot-home/dev-monitoring/trackers": "Device Monitoring",
      "/iot-home/dev-monitoring/trackers/home": "Device Monitoring",
      "/iot-home/dev-monitoring/vcb-panel": "Device Monitoring",
      "/iot-home/dev-monitoring/vcb/vcb-details": "Device Monitoring",
      "/iot-home/dev-monitoring/ht-panel": "Device Monitoring",
      "/iot-home/dev-monitoring/weather-dashboard": "Device Monitoring",
      "/iot-home/dev-monitoring/inv-dashboard/compare-inv": "Device Monitoring",
      "/iot-home/dev-monitoring/inv-dashboard/inv-details": "Device Monitoring",
    };


    const activeTab = tabMapping[pathname] || "";
    setActiveTab(activeTab);
    const deviceTabMapping = {
      "/iot-home/dev-monitoring/inv-dashboard": "Inverter",
      "/iot-home/dev-monitoring/inv-dashboard/inv-details": "Inverter",
      "/iot-home/dev-monitoring/inv-dashboard/compare-inv": "Inverter",
      "/iot-home/dev-monitoring/mfm": "MFM",
      "/iot-home/dev-monitoring/mfm-data": "MFM",
      "/iot-home/dev-monitoring/abt": "ABT",
      "/iot-home/dev-monitoring/smb": "SMB",
      "/iot-home/dev-monitoring/smb/smb-details": "SMB",
      "/iot-home/dev-monitoring/trackers": "Trackers",
      "/iot-home/dev-monitoring/trackers/home": "Trackers",
      "/iot-home/dev-monitoring/vcb-panel": "VCB Panel",
      "/iot-home/dev-monitoring/vcb/vcb-details": "VCB Panel",
      "/iot-home/dev-monitoring/ht-panel": "HT Panel",
      "/iot-home/dev-monitoring/weather-dashboard": "Weather Dashboard",
      
    };
    const selectedTab = deviceTabMapping[pathname];
    setSelectedTab(selectedTab);
  };
  useEffect(() => {
    handleMenuItemClick(setActiveTab);
  }, [setActiveTab]);

  const monitoringBoxStyle = {
    position: "absolute",
    width: "auto",
    minHeight: "100vh",
    maxHeight: "100vh",
    bgcolor: "background.paper",
    boxShadow: "none",
    left: isOpenSidebar ? "3.4rem" : "5rem",
    p: 4,
    top: "-8vh",
    padding: "1rem",
    zIndex: "5",
    overflowY: "auto",
    border: "1px solid #D8D8D8",
  };
  const handleOutsideClick = (e) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target)) {
      setOpenDrawer(false);
    }
  };

  const handleArrowClick = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };
  useEffect(() => {
    if (openDrawer) {
      document.addEventListener("mousedown", handleOutsideClick); // Add listener when drawer is open
    } else {
      document.removeEventListener("mousedown", handleOutsideClick); // Remove listener when drawer is closed
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick); // Cleanup listener on unmount
    };
  }, [openDrawer]);

  const devicesList = [
    {
      name: "Inverter",
      icon: InverterIcon,
      fillColor: "black",
      path1:
        "M20 4H19.5V1H22C22.276 1 22.5 0.776 22.5 0.5C22.5 0.224 22.276 0 22 0H16C15.724 0 15.5 0.224 15.5 0.5C15.5 0.776 15.724 1 16 1H18.5V4H5C2.519 4 0.5 6.019 0.5 8.5V15.5C0.5 17.456 1.754 19.124 3.5 19.743V21.5C3.5 21.776 3.724 22 4 22C4.276 22 4.5 21.776 4.5 21.5V19.972C4.664 19.99 4.831 20 5 20H20C20.169 20 20.336 19.99 20.5 19.972V21.5C20.5 21.776 20.724 22 21 22C21.276 22 21.5 21.776 21.5 21.5V19.743C23.246 19.124 24.5 17.456 24.5 15.5V8.5C24.5 6.019 22.481 4 20 4ZM23.5 15.5C23.5 17.43 21.93 19 20 19H5C3.07 19 1.5 17.43 1.5 15.5V8.5C1.5 6.57 3.07 5 5 5H20C21.93 5 23.5 6.57 23.5 8.5V15.5ZM2.5 2.5C2.5 2.224 2.724 2 3 2H9C9.276 2 9.5 2.224 9.5 2.5C9.5 2.776 9.276 3 9 3H3C2.724 3 2.5 2.776 2.5 2.5ZM17.5 8C15.294 8 13.5 9.794 13.5 12C13.5 14.206 15.294 16 17.5 16C19.706 16 21.5 14.206 21.5 12C21.5 9.794 19.706 8 17.5 8ZM17.5 15C16.016 15 14.781 13.917 14.542 12.5H17C17.276 12.5 17.5 12.276 17.5 12C17.5 11.724 17.276 11.5 17 11.5H14.542C14.781 10.083 16.016 9 17.5 9C19.154 9 20.5 10.346 20.5 12C20.5 13.654 19.154 15 17.5 15ZM7.5 8C5.294 8 3.5 9.794 3.5 12C3.5 14.5 5.5 16 7.5 16C9.706 16 11.5 14.206 11.5 12C11.5 9.794 9.706 8 7.5 8ZM7.5 15C6.853 15 6.254 14.793 5.763 14.444L7.5 12.707C7.695 12.512 7.695 12.195 7.5 12C7.305 11.805 6.988 11.805 6.793 12L5.056 13.737C4.706 13.247 4.5 12.647 4.5 12C4.5 10.346 5.846 9 7.5 9C9.154 9 10.5 10.346 10.5 12C10.5 13.654 9.154 15 7.5 15Z",
    },
    {
      name: "MFM",
      icon: InverterIcon,
      fillColor: "black",
      path1:
        "M7.5 17H17.5V7H7.5V17ZM8.5 8H16.5V16H8.5V8ZM24.5 8V7H22.5V4.5C22.5 3.12 21.38 2 20 2H17.5V0H16.5V2H14.5V0H13.5V2H11.5V0H10.5V2H8.5V0H7.5V2H5C3.62 2 2.5 3.12 2.5 4.5V7H0.5V8H2.5V10H0.5V11H2.5V13H0.5V14H2.5V16H0.5V17H2.5V19.5C2.5 20.88 3.62 22 5 22H7.5V24H8.5V22H10.5V24H11.5V22H13.5V24H14.5V22H16.5V24H17.5V22H20C21.38 22 22.5 20.88 22.5 19.5V17H24.5V16H22.5V14H24.5V13H22.5V11H24.5V10H22.5V8H24.5ZM20 21H5C4.17 21 3.5 20.33 3.5 19.5V4.5C3.5 3.67 4.17 3 5 3H20C20.83 3 21.5 3.67 21.5 4.5V19.5C21.5 20.33 20.83 21 20 21Z",
    },
    {
      name: "ABT",
      icon: AbtIcon,
      fillColor: "black",
      path1:
        "M7.5 17H17.5V7H7.5V17ZM8.5 8H16.5V16H8.5V8ZM24.5 8V7H22.5V4.5C22.5 3.12 21.38 2 20 2H17.5V0H16.5V2H14.5V0H13.5V2H11.5V0H10.5V2H8.5V0H7.5V2H5C3.62 2 2.5 3.12 2.5 4.5V7H0.5V8H2.5V10H0.5V11H2.5V13H0.5V14H2.5V16H0.5V17H2.5V19.5C2.5 20.88 3.62 22 5 22H7.5V24H8.5V22H10.5V24H11.5V22H13.5V24H14.5V22H16.5V24H17.5V22H20C21.38 22 22.5 20.88 22.5 19.5V17H24.5V16H22.5V14H24.5V13H22.5V11H24.5V10H22.5V8H24.5ZM20 21H5C4.17 21 3.5 20.33 3.5 19.5V4.5C3.5 3.67 4.17 3 5 3H20C20.83 3 21.5 3.67 21.5 4.5V19.5C21.5 20.33 20.83 21 20 21Z",
    },

    {
      name: "SMB",
      icon: InverterIcon,
      fillColor: "black",
      path1:
        "M20 0H3C1.622 0 0.5 1.122 0.5 2.5V17.5C0.5 18.708 1.36 19.717 2.5 19.95V24H20.5V19.95C21.64 19.718 22.5 18.708 22.5 17.5V2.5C22.5 1.122 21.378 0 20 0ZM3.5 23V20H19.5V23H3.5ZM21.5 17.5C21.5 18.327 20.827 19 20 19H3C2.173 19 1.5 18.327 1.5 17.5V2.5C1.5 1.673 2.173 1 3 1H20C20.827 1 21.5 1.673 21.5 2.5V17.5ZM4.5 12H18.5V4H4.5V12ZM5.5 5H17.5V11H5.5V5ZM6.5 15.5C6.5 16.052 6.052 16.5 5.5 16.5C4.948 16.5 4.5 16.052 4.5 15.5C4.5 14.948 4.948 14.5 5.5 14.5C6.052 14.5 6.5 14.948 6.5 15.5ZM8.5 15H18.5V16H8.5V15Z",
    },
    {
      name: "Trackers",
      icon: TrackerIcon,
    },
    {
      name: "VCB Panel",
      icon: VCBPanelIcon,
    },
    {
      name: "HT Panel",
      icon: HTPanelIcon,
    },

    {
      name: "Weather Dashboard",
      icon: InverterIcon,
      fillColor: "#393939",
      path1:
        "M7.6235 19H16.3765C16.5392 18.9955 16.6972 19.0549 16.8168 19.1654C16.9363 19.2758 17.0079 19.4287 17.0163 19.5913C17.0247 19.7538 16.9691 19.9132 16.8615 20.0354C16.7539 20.1575 16.6028 20.2328 16.4405 20.245L16.3765 20.248H7.6235C7.46078 20.2525 7.30276 20.1931 7.18321 20.0826C7.06366 19.9722 6.99206 19.8193 6.9837 19.6567C6.97535 19.4942 7.0309 19.3348 7.13849 19.2126C7.24608 19.0905 7.39718 19.0152 7.5595 19.003L7.6235 19ZM4.6235 16.5H19.376C19.5365 16.4992 19.691 16.5603 19.8076 16.6706C19.9242 16.7808 19.9939 16.9317 20.0021 17.092C20.0104 17.2522 19.9566 17.4095 19.8519 17.5311C19.7472 17.6527 19.5997 17.7293 19.44 17.745L19.376 17.748H4.624C4.46354 17.7488 4.30896 17.6877 4.19236 17.5774C4.07576 17.4672 4.0061 17.3163 3.99787 17.156C3.98963 16.9958 4.04344 16.8385 4.14812 16.7169C4.2528 16.5953 4.40031 16.5187 4.56 16.503L4.6235 16.5ZM12 3.5C15.1685 3.5 16.966 5.5975 17.2275 8.13H17.3075C19.3465 8.13 20.9995 9.779 20.9995 11.8125C20.9995 13.846 19.3465 15.495 17.3075 15.495H6.6925C4.653 15.495 3 13.8465 3 11.8125C3 9.7785 4.653 8.13 6.692 8.13H6.772C7.035 5.5805 8.831 3.5 12 3.5ZM12 4.7475C9.8695 4.7475 8.0125 6.4715 8.0125 8.8525C8.0125 9.23 7.6845 9.5265 7.3085 9.5265H6.5985C5.3015 9.5265 4.25 10.583 4.25 11.8865C4.25 13.1905 5.3015 14.2475 6.5985 14.2475H17.4015C18.6985 14.2475 19.75 13.1905 19.75 11.887C19.75 10.5835 18.6985 9.526 17.4015 9.526H16.6915C16.3155 9.526 15.9875 9.23 15.9875 8.853C15.9875 6.441 14.1305 4.748 12 4.748",
    },
    
  ];
  const sidebarTabs = [
    {
      name: "Executive Dashboard",
      icon: DashboardIcon,
    },
    {
      name: "Device Monitoring",
      icon: MonitoringIcon,
    },
    {
      name: "Reporting Hub",
      icon: ReportsIcon,
    },
    {
      name: "Early Warning System",
      icon: AlarmssIcon,
    },
    {
      name: "Fault Analysis",
      icon: ReportFailureIcon,
    },
    {
      name: "Plant Layout",
      icon: PlantViewIcon,
    },
    {
      name: "3D Digital Twin",
      icon: ThreeDIcon,
    },
    {
      name: "AI Doc Explorer",
      icon: FileIcon,
    },
  ];
  const handleMenuItemClick = async(tab) => {
    setActiveTab(tab); // Update the active tab state
    switch (tab) {
      case "Executive Dashboard":
        history.push("/iot-home");
        break;
      case "Reporting Hub":
        history.push("/iot-home/reports");
        break;
      case "Early Warning System":
        history.push("/iot-home/alarms");
        break;
      case "Fault Analysis":
        history.push("/iot-home/fault_analysis");
        break;
      case "Plant Layout":
        history.push("/iot-home/plant-layout");
        break;
      case "3D Digital Twin":
        history.push("/iot-home/three-d-twin");
        break;
        case "AI Doc Explorer":
          history.push("/iot-home/file-upload");
          break;
      case "Device Monitoring":
        setOpenDrawer(!openDrawer);
        break;
      default:
        break;
    }
  };
  

  const handleMonitorDevices = () => {
    history.push("/iot-home/dev-monitoring");
  };
  const handleMenuClick = async(deviceName) => {
    const routes = {
      Inverter: "/iot-home/dev-monitoring/inv-dashboard",
      MFM: "/iot-home/dev-monitoring/mfm",
      ABT: "/iot-home/dev-monitoring/abt",
      SMB: "/iot-home/dev-monitoring/smb",
      Trackers: "/iot-home/dev-monitoring/trackers",
      "VCB Panel": "/iot-home/dev-monitoring/vcb-panel",
      "HT Panel": "/iot-home/dev-monitoring/ht-panel",
      "Weather Dashboard": "/iot-home/dev-monitoring/weather-dashboard",
    };

    if (routes[deviceName]) {
      if (deviceName === "MFM") {
        history.push({
          pathname: routes[deviceName],
        });
      } else {
        history.push(routes[deviceName]);
      }
      setOpenDrawer(false);
    }
    setSelectedTab(deviceName);
  };

  const mainListItems = (
    <React.Fragment>
      {sidebarTabs.map((item, index) => {
        const isActive = activeTab === item.name;
        const iconColor =
          isActive && !isOpenSidebar
            ? "#ffff"
            : isActive
            ? "#2F67F8"
            : "#111111";
        return (
          <ListItemButton
            key={index}
            focusVisibleClassName="sidebar-selected-iot"
            selected={isActive}
            autoFocus={true}
            onClick={() => handleMenuItemClick(item.name)}
            sx={{
              justifyContent: isOpenSidebar
                ? "flex-start !important"
                : "center !important",
              width: "100%",
              backgroundColor: "#F9F9FF",
            }}
          >
            <Tooltip title={item.name} arrow>
              <ListItemIcon className="center_center_flex">
                <div
                  className="listItem_icon"
                  style={{
                    background:
                      isActive && !isOpenSidebar ? "#2F67F8" : "transparent",
                    padding: !isOpenSidebar ? "0.4rem" : "0vh",
                  }}
                >
                  {item.icon ? (
                    <item.icon
                      width="1.7rem"
                      height="1.7rem"
                      strokeColor={iconColor}

                    />
                  ) : (
                    <div>--</div>
                  )}
                  {isOpenSidebar && (
                    <p
                      className="Poppins_16px_rem"
                      style={{
                        color: isActive ? "#2F67F8" : "#111111",
                        fontWeight: isActive ? "600" : "400",
                      }}
                    >
                      {item.name}
                    </p>
                  )}
                </div>
              </ListItemIcon>
            </Tooltip>
          </ListItemButton>
        );
      })}
    </React.Fragment>
  );
  const handleLogoClick = () => {
    history.push("/iot-home");
  };
  return (
    <div>
      <div onClick={handleLogoClick} style={{ cursor: "pointer" }}>
        {openDrawer ? (
          <div
            className="sidebar-main-drawer"
            style={{
              marginLeft: isOpenSidebar ? "0rem" : "1rem",
            }}
          >
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/Logo.svg"
              alt="logo"
              style={{ width: "10rem" }}
            />
          </div>
        ) : (
          <div className="logo-container">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/smarttrak_logo.svg"
              className="sidebar_logo"
              alt="smarttrakLogo"
            />
          </div>
        )}
      </div>
      <div className= {isMobile ? "display_none" : "display_block"}>
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              width: isOpenSidebar ? "max-content" : "5rem",
              height: { height },
              transition: "transform 0.3s",
              transform: "translateY(0)",
              overflowY: "inherit ! important",
              backgroundColor: "#F9F9FF",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            backgroundColor: "#F9F9FF",
          }}
          variant="permanent"
          open={open}
          className="custom-drawer-iot"
        >
          <List
            component="nav"
            className="nav_list"
            sx={{ width: isOpenSidebar ? "100%" : "5rem" }}
          >
            {mainListItems}
          </List>
          {openDrawer && (
            <Box ref={drawerRef} sx={monitoringBoxStyle} component={Paper}>
              <div onClick={handleMonitorDevices} style={{ cursor: "pointer" }}>
                <p className="Poppins_18px_rem">Monitoring Devices</p>
              </div>
              <div className="monitoring-div">
                {devicesList.map((device, index) => {
                  return (
                    <div
                      onClick={() => handleMenuClick(device.name)}
                      className="monitoring-menu"
                      style={{
                        backgroundColor:
                          selectedTab === device.name
                            ? "#BBD6FF"
                            : "transparent",
                      }}
                      key={index}
                    >
                      {device.icon ? (
                        <device.icon
                          width="1.7rem"
                          fillColor={device.fillColor}
                          fillColorPath2={device.fillColorPath2}
                          strokeColorPath2={device.strokeColorPath2}
                          height="1.7rem"
                          path1={device.path1}
                          path2={device.path2}
                        />
                      ) : (
                        <div>--</div>
                      )}

                      {/* <img src={device.icon} alt="device" style={{ width: "1.7rem" }} /> */}
                      <p
                        className="Poppins_16px_rem"
                        style={{ fontWeight: "400" }}
                      >
                        {device.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </Box>
          )}
          <div onClick={handleArrowClick} className="toggle-arrow-div">
            {isOpenSidebar ? (
              <KeyboardDoubleArrowLeftIcon className="left-right-arrow" />
            ) : (
              <KeyboardDoubleArrowRightIcon className="left-right-arrow" />
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default SideBar;
