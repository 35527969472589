import React, { useState, useEffect } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import "./Signin.scss";
import { useHistory } from "react-router-dom";
import { AuthService } from "../LifeScience/services/AuthService";
import Slider from "react-slick";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../LifeScience/components/common/SimpleBackdrop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import BoldCommonText from "../IOT/Common/BoldCommonText";
import CommonText from "../IOT/Common/CommonText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { jwtDecode } from "jwt-decode";
import { UserService } from "../IOT/Services/UserService";
import ModalPopup from "../IOT/Common/ModalPopup";

const authService = new AuthService();
const userService = new UserService();

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sliderContent = [
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/images/firstImage.png",
    },
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/images/secImage.png",
    },
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/images/thirdImg.png",
    },
  ];
  const validateFields = () => {
    let valid = true;
    if (!email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter your password");
      valid = false;
    } else {
      setPasswordError("");
    }

    return valid;
  };
  const clientId =
    "725956637188-phc797j31003pfoiok3cm40bsj3khfje.apps.googleusercontent.com";
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    const email = decoded.email;
    let reqData = {
      token: token,
    };
    setLoading(true);
    if (clientId) {
      await userService
        .googleLogin(reqData)
        .then(async(res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            localStorage.setItem("role", res.data.user.role);
            localStorage.setItem("authToken", res.data.access_token);
            localStorage.setItem("email", email);

            if (res.data.user.role === "DEFAULT") {
              setIsDefault(true);
              setOpenDrawer(true);
            } else if (res.data.user.role === "MANAGER" || res.data.user.role === "EXECUTIVE") {
              try {
                setLoading(true);
                const res = await userService.getPlantsList();
                if (res.status === 200) {
                  const idArray = res.data.map((plant) => plant.id);
                localStorage.setItem("plantId", idArray[0]);
                  history.push("/iot-home")
                }
                setLoading(false);
              } catch (err) {
                console.log("Error in fetchAllPlants", err);
                enqueueSnackbar(
                  err.response?.data?.detail
                    ? err.response?.data?.detail
                    : "Error while fetching plant lists",
                  { variant: "error" }
                );
                setLoading(false);
              }
            } else if (
              res.data.user.role === "TECSTAFF"
            ){
              try {
               setLoading(true);
               const res = await userService.getPlantsList();
               if (res.status === 200) {
                 const idArray = res.data.map((plant) => plant.id);
                 localStorage.setItem("plantId", idArray[0]);
                 history.push({pathname:"/iot-home"})
               }
               setLoading(false);
             } catch (err) {
               console.log("Error in fetchAllPlants", err);
               enqueueSnackbar(
                err.response.data.detail,
                 { variant: "error" }
               );
               setLoading(false);
             }
             } else {
              history.push("/dashboard");
              enqueueSnackbar("User has logged in successfully", {
                variant: "success",
              });
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(
            err.response.data.detail,
            { variant: "error" }
          );
        });
    }
  };

  const onFailure = (error) => {
    console.log("Google sign-in failed!");
    console.log(error);
  };

  const handleForgotPass = () => {
    history.push({
      pathname: "/forgot-password",
    });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      const data = new FormData(event.currentTarget);
      const reqData = {
        email: data.get("email"),
        password: data.get("password"),
      };
      await userService
        .postSignin(reqData)
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            localStorage.setItem("role", res.data.user.role);
            localStorage.setItem("authToken", res.data.access_token);
            localStorage.setItem("email", data.get("email"));

            if (res.data.user.role === "DEFAULT") {
              setIsDefault(true);
              setOpenDrawer(true);
            } else if (res.data.user.role === "MANAGER" || res.data.user.role === "EXECUTIVE") {
              try {
                setLoading(true);
                const res = await userService.getPlantsList();
                if (res.status === 200) {
                  const idArray = res.data.map((plant) => plant.id);
                localStorage.setItem("plantId", idArray[0]);
                  history.push("/iot-home")
                }
                setLoading(false);
              } catch (err) {
                console.log("Error in fetchAllPlants", err);
                enqueueSnackbar(
                  err.response.data.detail,
                  { variant: "error" }
                );
                setLoading(false);
              }
            } else if (
              res.data.user.role === "TECSTAFF"
            ){
              try {
               setLoading(true);
               const res = await userService.getPlantsList();
               if (res.status === 200) {
                 const idArray = res.data.map((plant) => plant.id);
                 localStorage.setItem("plantId", idArray[0]);
                 history.push({pathname:"/iot-home"})
               }
               setLoading(false);
             } catch (err) {
               console.log("Error in fetchAllPlants", err);
               enqueueSnackbar(
                err.response.data.detail,
                 { variant: "error" }
               );
               setLoading(false);
             }
             } else {
              history.push("/dashboard");
              enqueueSnackbar("User has logged in successfully", {
                variant: "success",
              });
            }
          } else if (res.status === 422) {
            enqueueSnackbar("Please fill the valid credientials", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(
            err?.response?.data?.detail
              ? err.response.data.detail
              : "Error while logging in",
            { variant: "error" }
          );
        });
    } else {
      setLoading(false);
    }
  };

  const handleLogoClick = ()=>{
    history.push("/")
  }
  const [sliderSettings] = useState({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  });

  return (
    <div className="container">
      {loading && <SimpleBackdrop open={loading} />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isDefault && (
          <ModalPopup openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        )}
        <div onClick={handleLogoClick} style={{cursor:"pointer"}}>
          <img
            src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg"
            width={160}
            alt="img"
            className="smartlogo-img"
          />
        </div>

        <div className="login-form">
          <div className="form-container">
            <div className="up-title">Sign in to Smarttrak</div>

            <form onSubmit={handleSubmit} className="form-data">
              <input
                type="text"
                name="email"
                value={email}
                placeholder="Email"
                onChange={handleEmailChange}
                className={`email-input ${emailError ? "error" : ""}`}
              />
              {emailError && <div className="error-message">{emailError}</div>}
              <br />
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  name="password"
                  onChange={handlePasswordChange}
                  className={`password-input ${passwordError ? "error" : ""}`}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "60%",
                    right: "5px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </span>
              </div>
              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
              <br />
              <span className="forgot-pass" onClick={handleForgotPass}>
                Forgot Password ?
              </span>
              <button type="submit" className="btn">
                <div className="btn-text">Sign in</div>
              </button>

              <br />
              <div className="login-option">or Login Via</div>
              <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
              </GoogleOAuthProvider>
            </form>
            <div className="register">
              Don’t have an account ?{" "}
              <a href="signup" className="register-link">
                Register
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="half-screen">
        <Slider {...sliderSettings}>
          {sliderContent.map((slide, index) => (
            <div key={index} className="half-screen-slide-container">
              <img src={slide.imgSrc} alt="slide" className="img1" />
              <BoldCommonText
                type="SemiBold_500"
                styles={{ color: "#fff" }}
                className="sec-title"
                text="Welcome to No Code AI Platform"
              />
              <CommonText
                type="Regular_400"
                styles={{ color: "hsla(0,0%,100%,.75)" }}
                className="sub-title"
                text="With our pre-built templates and drag-and-drop interface, you can easily upload your data, preprocess it"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}