import React from 'react';

const ThreeDIcon = (props) => {
    const {
        strokeColor = '#111111',
        strokeWidth = 1.5,
        width = "24",
        height = "24",
        ...rest
    } = props;
  return (
    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M12.3492 8.85551C12.4031 8.89338 12.404 8.97303 12.3509 9.01206L6.53359 13.2868C6.50004 13.3115 6.45445 13.3118 6.42055 13.2876L0.897141 9.34704C0.844968 9.30982 0.842984 9.23299 0.893167 9.19312L6.43623 4.78969C6.46966 4.76313 6.51659 4.76174 6.55154 4.78627L12.3492 8.85551Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M0.602086 9.8838C0.603236 9.79826 0.700012 9.74924 0.769656 9.79892L6.24735 13.7065C6.27591 13.7269 6.29255 13.7601 6.29177 13.7952L6.12409 21.4098C6.1222 21.4956 6.02441 21.5437 5.95527 21.4928L0.543899 17.5104C0.516347 17.4901 0.500273 17.4578 0.500733 17.4235L0.602086 9.8838Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M12.6533 9.54074C12.6527 9.46226 12.5635 9.41742 12.5001 9.46369L6.70486 13.6944C6.68057 13.7121 6.66596 13.7402 6.66533 13.7703L6.50641 21.4044C6.50476 21.4834 6.59361 21.5307 6.65822 21.4853L12.6753 17.252C12.7011 17.2338 12.7164 17.204 12.7162 17.1724L12.6533 9.54074Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M27.5384 9.55845C27.5367 9.74359 27.5619 9.9276 27.6127 10.1017L24.1141 12.6715C23.9869 12.4404 23.8165 12.2509 23.6174 12.1193C23.4183 11.9877 23.1964 11.9178 22.9707 11.9157C22.7354 11.9121 22.5032 11.9837 22.2967 12.1235C22.0901 12.2634 21.916 12.4667 21.7911 12.714L18.5269 10.3898C18.5945 10.1795 18.6288 9.95495 18.6279 9.72851C18.6296 9.32919 18.5176 8.94193 18.3115 8.63494L21.7416 5.90691C21.8649 6.18064 22.0464 6.40722 22.2664 6.56201C22.4864 6.71681 22.7364 6.79389 22.9892 6.78488C23.2421 6.77587 23.4881 6.68111 23.7005 6.51091C23.9129 6.34071 24.0837 6.10158 24.1941 5.81952L27.889 8.41528C27.664 8.73089 27.5394 9.13725 27.5384 9.55845Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M18.4407 17.6362C18.4415 17.9717 18.3628 18.3002 18.2144 18.5808C18.0661 18.8614 17.8545 19.082 17.606 19.215C17.3575 19.3481 17.083 19.3879 16.8164 19.3295C16.5499 19.2711 16.303 19.1171 16.1064 18.8864C15.9098 18.6558 15.772 18.3588 15.7103 18.0321C15.6486 17.7054 15.6655 17.3634 15.759 17.0486C15.8525 16.7337 16.0185 16.4598 16.2364 16.2607C16.4543 16.0616 16.7145 15.9461 16.9849 15.9285C17.0096 15.9261 17.0345 15.9261 17.0592 15.9285C17.4248 15.9292 17.7753 16.1091 18.0342 16.4291C18.2931 16.7491 18.4392 17.1831 18.4407 17.6362Z" stroke={strokeColor}strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M22.7253 15.3145L22.6224 20.0951C22.3887 20.1217 22.1645 20.2226 21.9715 20.3881C21.7786 20.5536 21.6234 20.7781 21.521 21.0398L18.2052 18.5953C18.3454 18.3376 18.4265 18.0376 18.4398 17.7272C18.4531 17.4168 18.3981 17.1079 18.2807 16.8332C18.1633 16.5586 17.9878 16.3288 17.7731 16.1682C17.5585 16.0076 17.3126 15.9223 17.0619 15.9215C17.0371 15.9191 17.0123 15.9191 16.9875 15.9215L17.0504 11.4174C17.1189 11.4328 17.1885 11.4399 17.2581 11.4386C17.4659 11.4401 17.6713 11.3835 17.859 11.2731C18.0467 11.1627 18.2119 11.0013 18.3424 10.8009L21.6429 13.1628C21.6029 13.3202 21.583 13.4844 21.5838 13.6494C21.5867 14.0487 21.7025 14.434 21.9111 14.7383C22.1197 15.0426 22.4079 15.2465 22.7253 15.3145Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M29.162 15.6853C29.148 15.683 29.134 15.683 29.12 15.6853C28.9385 15.685 28.7588 15.7291 28.591 15.8151C28.4233 15.901 28.2709 16.0271 28.1426 16.1862C28.0142 16.3453 27.9125 16.5342 27.8431 16.7421C27.7738 16.95 27.7382 17.1728 27.7385 17.3977C27.7367 17.7154 27.8088 18.027 27.9462 18.2953L24.0035 21.0729C23.9084 20.8282 23.7677 20.615 23.5931 20.4507C23.4185 20.2865 23.215 20.1759 22.9993 20.1281L23.0965 15.3476C23.4387 15.3076 23.7565 15.111 23.9881 14.7961C24.2196 14.4812 24.348 14.0707 24.3484 13.6446C24.3496 13.4599 24.3257 13.2763 24.2779 13.1014L27.7823 10.5434C27.9102 10.7723 28.0812 10.9592 28.2804 11.088C28.4795 11.2168 28.701 11.2836 28.9257 11.2827C28.994 11.2841 29.0623 11.2769 29.1296 11.2615L29.162 15.6853Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M24.3374 5.07555C24.3385 5.33334 24.2916 5.58798 24.2002 5.81956C24.0524 6.19991 23.7968 6.49945 23.4821 6.66097C23.1674 6.82249 22.8157 6.83467 22.4943 6.69517C22.1728 6.55568 21.904 6.27429 21.7394 5.90475C21.5747 5.53521 21.5256 5.10343 21.6015 4.69189C21.6774 4.28034 21.8729 3.91786 22.1508 3.6737C22.4286 3.42954 22.7693 3.32081 23.1078 3.36827C23.4462 3.41573 23.7587 3.61606 23.9855 3.93099C24.2123 4.24592 24.3376 4.65339 24.3374 5.07555Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M18.6353 9.72854C18.6362 9.95499 18.6019 10.1795 18.5343 10.3899C18.4836 10.5332 18.4196 10.6687 18.3437 10.7938C18.2142 10.9971 18.0491 11.1614 17.861 11.2743C17.6729 11.3872 17.4666 11.4458 17.2575 11.4457C17.1879 11.447 17.1183 11.4399 17.0498 11.4244C16.7872 11.3764 16.5412 11.2359 16.3412 11.0196C16.1411 10.8033 15.9954 10.5203 15.9214 10.2043C15.8474 9.88835 15.8482 9.55265 15.9237 9.23719C15.9992 8.92174 16.1463 8.63982 16.3473 8.42499C16.5484 8.21016 16.7951 8.07147 17.0579 8.02542C17.3208 7.97936 17.5888 8.02789 17.83 8.16523C18.0713 8.30256 18.2756 8.52292 18.4187 8.80007C18.5618 9.07722 18.6376 9.3995 18.6372 9.72854H18.6353Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M24.1083 21.9116C24.1079 22.3377 23.9794 22.7482 23.7479 23.0631C23.5164 23.378 23.1985 23.5746 22.8563 23.6146C22.8144 23.6146 22.7705 23.6146 22.7229 23.6146C22.6428 23.6151 22.563 23.6048 22.4847 23.5839C22.1646 23.5159 21.8743 23.3098 21.6651 23.002C21.4558 22.6942 21.3412 22.3047 21.3413 21.9022C21.3405 21.7372 21.3604 21.573 21.4004 21.4156C21.4352 21.2629 21.4878 21.1172 21.5567 20.9834C21.6802 20.7388 21.8518 20.5372 22.0555 20.3976C22.2592 20.2579 22.4882 20.1848 22.721 20.1851C22.9466 20.1872 23.1685 20.2571 23.3677 20.3887C23.5668 20.5203 23.7372 20.7098 23.8643 20.9409C24.017 21.2258 24.0985 21.5602 24.0987 21.9022L24.1083 21.9116Z" stroke={strokeColor}strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M30.4995 17.393C30.4993 17.765 30.4013 18.1268 30.2203 18.4235C30.0392 18.7202 29.785 18.9357 29.4963 19.0372C29.2075 19.1387 28.8999 19.1207 28.6202 18.9859C28.3404 18.8512 28.1037 18.6071 27.946 18.2905C27.8087 18.0223 27.7365 17.7107 27.7383 17.393C27.7381 17.168 27.7736 16.9452 27.843 16.7373C27.9123 16.5294 28.0141 16.3405 28.1424 16.1815C28.2707 16.0224 28.4231 15.8963 28.5909 15.8103C28.7586 15.7244 28.9384 15.6803 29.1199 15.6806C29.1338 15.6783 29.1479 15.6783 29.1618 15.6806C29.5209 15.6935 29.8617 15.8798 30.1116 16.1997C30.3615 16.5196 30.5007 16.9478 30.4995 17.393Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M30.3037 9.55849C30.3036 9.96821 30.1849 10.3643 29.9692 10.6747C29.7534 10.9851 29.4548 11.1891 29.1279 11.2496C29.0607 11.2651 28.9924 11.2722 28.924 11.2709C28.6325 11.2741 28.3479 11.1614 28.1119 10.9492C27.8759 10.7371 27.701 10.4367 27.613 10.0923C27.5621 9.91819 27.537 9.73418 27.5387 9.54904C27.5387 9.32377 27.5745 9.10071 27.6442 8.89265C27.7138 8.68458 27.816 8.4956 27.9446 8.33652C28.0733 8.17745 28.2261 8.05142 28.3942 7.96564C28.5622 7.87986 28.7423 7.83602 28.924 7.83665C29.106 7.83664 29.2862 7.88126 29.4542 7.96794C29.6222 8.05461 29.7747 8.18163 29.903 8.34167C30.0312 8.50172 30.1326 8.69163 30.2014 8.90046C30.2702 9.1093 30.3049 9.33293 30.3037 9.55849Z" stroke={strokeColor}strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M24.3487 13.6352C24.3484 14.0612 24.2199 14.4717 23.9884 14.7866C23.7569 15.1015 23.439 15.2982 23.0968 15.3381C23.0548 15.3381 23.011 15.3381 22.9634 15.3381C22.8833 15.3387 22.8034 15.3284 22.7252 15.3074C22.4051 15.2394 22.1148 15.0333 21.9056 14.7255C21.6963 14.4178 21.5816 14.0282 21.5818 13.6257C21.581 13.4607 21.6009 13.2966 21.6409 13.1392C21.6756 12.9864 21.7283 12.8408 21.7971 12.7069C21.9206 12.4624 22.0923 12.2608 22.296 12.1211C22.4997 11.9815 22.7287 11.9083 22.9615 11.9086C23.1871 11.9107 23.409 11.9806 23.6081 12.1122C23.8073 12.2439 23.9777 12.4333 24.1048 12.6644C24.2574 12.9494 24.339 13.2837 24.3392 13.6257L24.3487 13.6352Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M9.6106 3.35844H16.3602" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    <path d="M16.3601 3.35843V1.53974C16.36 1.51398 16.3654 1.48863 16.3757 1.46627C16.386 1.44391 16.401 1.42532 16.4189 1.41238C16.4369 1.39944 16.4573 1.39261 16.4781 1.39258C16.4989 1.39255 16.5193 1.39931 16.5373 1.4122L17.8084 2.32154L19.0794 3.24269C19.0972 3.25561 19.1119 3.27406 19.1222 3.29623C19.1324 3.3184 19.1378 3.3435 19.1378 3.36906C19.1378 3.39461 19.1324 3.41971 19.1222 3.44188C19.1119 3.46405 19.0972 3.48251 19.0794 3.49542L17.8084 4.40712L16.5373 5.31647C16.5193 5.32935 16.4989 5.33612 16.4781 5.33609C16.4573 5.33605 16.4369 5.32922 16.4189 5.31629C16.401 5.30335 16.386 5.28476 16.3757 5.2624C16.3654 5.24003 16.36 5.21469 16.3601 5.18892V3.35843Z" stroke={strokeColor} strokeWidth={strokeWidth} stroke-miterlimit="10"/>
    </svg>
    
  );
};

export default ThreeDIcon;