import React from "react";
const ToggleOff = (props) => {
  const {
    fillColor = "#FF4D50",
    ...rest                
  } = props;
  return (
    <svg
      width="22"
      height="13"
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.5C4.33333 12.5 2.91667 11.9167 1.75 10.75C0.583333 9.58333 0 8.16667 0 6.5C0 4.83333 0.583333 3.41667 1.75 2.25C2.91667 1.08333 4.33333 0.5 6 0.5H16C17.6667 0.5 19.0833 1.08333 20.25 2.25C21.4167 3.41667 22 4.83333 22 6.5C22 8.16667 21.4167 9.58333 20.25 10.75C19.0833 11.9167 17.6667 12.5 16 12.5H6ZM6 10.5H16C17.1 10.5 18.0417 10.1083 18.825 9.325C19.6083 8.54167 20 7.6 20 6.5C20 5.4 19.6083 4.45833 18.825 3.675C18.0417 2.89167 17.1 2.5 16 2.5H6C4.9 2.5 3.95833 2.89167 3.175 3.675C2.39167 4.45833 2 5.4 2 6.5C2 7.6 2.39167 8.54167 3.175 9.325C3.95833 10.1083 4.9 10.5 6 10.5ZM16 9.5C16.8333 9.5 17.5417 9.20833 18.125 8.625C18.7083 8.04167 19 7.33333 19 6.5C19 5.66667 18.7083 4.95833 18.125 4.375C17.5417 3.79167 16.8333 3.5 16 3.5C15.1667 3.5 14.4583 3.79167 13.875 4.375C13.2917 4.95833 13 5.66667 13 6.5C13 7.33333 13.2917 8.04167 13.875 8.625C14.4583 9.20833 15.1667 9.5 16 9.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default ToggleOff;